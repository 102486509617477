@tailwind base;
@tailwind components;
@tailwind utilities;
@import "primeicons/primeicons.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Philosopher:wght@400;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/freestyle-script');
@import url('https://fonts.googleapis.com/css?family=Oswald');
@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:ital,wght@0,400;0,700;1,400&display=swap');


// IMPORT 
@import 'styles/booking-primeng-custom';
@import 'styles/ota-primeng-custom';
@import 'styles/primeng-custom';


body {
    font-family: 'Inter', 'sans-serif';
    background-color: #f2f4f7;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
}

.root-component {
    position: relative;
}

//----------------------------Custom scroll-------------------------
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
}

//*****************************************************
