
// ************************************ OTA**********************
// ************************************ Left sidebar ************************************
.property-agency-info .left-sidebar {
    ::-webkit-scrollbar {
        width: 8px !important;
    }
    .p-timeline-event-opposite,
    .p-timeline-event-content {
        flex: none !important
    }
    .p-timeline-event-opposite,
    .p-timeline-event-content button {
        font-family: 'Inter', 'sans-serif' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
    .p-timeline-event {
        min-height: 60px !important;
    }
    .p-timeline-event-connector {
        display: none;
        flex-grow: 0;
        height: 20px;
        margin: auto;
    }
    .p-timeline .p-timeline-event-connector {
        background-color: #D0D5DD;
    }
    .p-timeline-event-opposite,
    .p-timeline-event-content {
        padding-left: 4px;
        padding-right: 0px !important
    }
}
.property-agency-info {
    .p-dialog .p-dialog-content {
        padding: 0px !important;
        border-radius: 0px !important;
    }
}
// -------------------------------------General information --------------------------
.general-information-ota-section {
    // checkbox
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: #D0D5DD;
        background-color: #F2F4F7;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #1570EF;
        background: #1570EF;
        color: #fff;
    }
    .p-checkbox .p-checkbox-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 4px !important;
    }
    .p-checkbox {
        width: 20px !important;
        height: 20px !important;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        color: #fff;
        font-weight: 600;
        font-size: 12px;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background: #1570EF;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        border-color: #1570EF;
        box-shadow: none;
    }
    .p-checkbox-box.p-disabled {
        background-color: #f2f4f7;
    }
    .is-invalid .p-checkbox-box {
        border: 1px solid #dc3545;
    }
    .itemFilter {
        color: #344054;
        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
    }
    // Radio button
    .p-radiobutton .p-radiobutton-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        &:hover {
            border-color: #1570EF !important;
        }
    }
    .p-radiobutton .p-radiobutton-box.p-highlight {
        background: #EFF8FF;
        border-color: #1570EF;
        &:hover {
            background: #EFF8FF;
        }
    }
    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background-color: #1570EF;
        width: 8px;
        height: 8px;
    }
    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: #1570EF;
        background: #EFF8FF;
    }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }
    // dropdown
    .p-dropdown {
        width: 100%;
    }
    .p-placeholder {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #98A2B3;
    }
    // muiti select
    p-multiselect {
        .p-disabled{
            color: #344054;
            background-color: #EAECF0 !important;
            opacity: 1 !important;
        }
        .p-multiselect-trigger {
            .pi.pi-chevron-down {
                font-size: 20px;
                margin-right: 7px;
            }
        }
    }
    .p-multiselect {
        width: 100%;
    }
    .p-multiselect:not(.p-disabled):hover {
        border-color: #D0D5DD;
    }
    .p-multiselect:not(.p-disabled).p-focus {
        box-shadow: none;
        color: #D0D5DD;
        border-color: #D0D5DD;
    }
    .p-multiselect-label {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
        color: black;
        background-color: white;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
    }
    .p-inputtext:enabled:hover {
        border-color: #D0D5DD;
    }
    // input number
    .p-inputnumber,
    .p-inputwrapper {
        display: block;
    }
    p-inputnumber {
        &.is-disable {
            .p-inputnumber-input {
                color: #344054;
                background-color: #EAECF0 !important;
                opacity: 1 !important;
            }
        }
    }

    .upload-file-input-disabled {
        .upload-file-section {
            background-color: #EAECF0;
        }
    }
    .p-dropdown.p-component {
        min-width: 100%;
        min-height: unset;
        height: 42px;
        max-width: 80%;
        &.p-disabled {
            background-color: #EAECF0 !important;
            opacity: 1 !important;
    
            .p-dropdown-label.p-placeholder {
                opacity: .6 !important;
            }
        }
    }
    .form-disable {
        font-size: 14px;
        border-radius: 8px;
        // border: 1px solid #d0d5dd;
        height: 44px;
        min-height: 44px;
        font-family: 'Inter';
        outline: none;
        color: #344054;
        background-color: #EAECF0;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    
        &:hover {
            border: 1px solid #d0d5dd !important;
        }
        &:focus {
            border: 1px solid #d0d5dd !important;
        }
    
        &.p-component:disabled {
            opacity: 1 !important;
        }
        .p-inputtext.p-component:hover {
            border: 1px solid #d0d5dd !important;
        }
        .p-inputtext:enabled:focus {
            border: 1px solid #d0d5dd !important;
            box-shadow: none !important;
        }
        .p-dropdown.p-component {
            min-width: 100%;
            min-height: unset;
            height: 44px;
            max-width: 80%;
            background-color: #EAECF0 !important;
            opacity: 1 !important;
            &.p-disabled {
                background-color: #EAECF0 !important;
                opacity: 1 !important;
        
                .p-dropdown-label.p-placeholder {
                    opacity: .6 !important;
                }
            }
        }
    }
}

// OTA
.ota-custom {
    .custom-input {
        .p-inputtext.p-component {
            font-family: 'Inter';
            height: 44px;
            min-height: 44px;
            border-radius: 8px;
            border: 1px solid #d0d5dd;
            padding: 12px 16px;
            height: 44px;
            font-size: 14px;
            line-height: 20px;
            outline: none;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            &.is-disable {
                color: #344054;
                background-color: #EAECF0 !important;
                opacity: 1 !important;
                border: 1px solid #d0d5dd !important;
                &:focus {
                    box-shadow: none;
                }
                &:hover {
                    border: 1px solid #d0d5dd !important;
                }
            }
            &.is-invalid {
                border: 1px solid #dc3545 !important;
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
                }
                &:hover {
                    border: 1px solid #dc3545 !important;
                }
            }
            &:hover {
                border: 1px solid #84caff !important;
            }
        }
        .unit-custom {
            .p-dropdown {
                padding: 4px;
                border-radius: 0px 8px 8px 0;
                .p-dropdown-label {
                    font-weight: 600 !important;
                }
            }
        }
        .p-dropdown {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 44px;
            padding: 4px 16px 4px 4px;
            border-radius: 8px;
            .p-dropdown-item {
                border-radius: 6px;
                color: #344054 !important;
                font-weight: 400 !important;
                &.p-highlight {
                    background-color: #f2f4f7;
                }
            }
            .p-dropdown-items-wrapper {
                padding: 4px;
                font-family: Inter, sans-serif;
                font-size: 14px;
                line-height: 20px;
                scrollbar-width: thin;
                scrollbar-color: #888 #f2f2f2;
                &::-webkit-scrollbar {
                    width: 6px !important;
                }
            }
            .p-dropdown-label {
                min-height: 44px;
                &.p-inputtext {
                    color: #344054;
                    font-family: Inter, sans-serif;
                    font-size: 14px;
                    line-height: 20px;
                }
                &.p-placeholder {
                    color: #98a2b3;
                    font-weight: 400;
                    min-height: 44px;
                }
                div {
                    justify-content: flex-start;
                }
            }
        }
        // checkbox
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: #D0D5DD;
            background-color: #F2F4F7;
        }
        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #1570EF;
            background: #1570EF;
            color: #fff;
        }
        .p-checkbox .p-checkbox-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 4px !important;
        }
        .p-checkbox {
            width: 20px !important;
            height: 20px !important;
        }
        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
            background: #1570EF;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
            border-color: #1570EF;
            box-shadow: none;
        }
        .p-dropdown-sort .p-dropdown {
            height: 38px;
            padding: 0 16px 0 8px;
            .p-inputtext {
                padding: 0 8px;
                min-width: 116px;
                min-height: 20px;
                line-height: 20px;
            }
            .p-dropdown-item {
                padding: 12px;
            }
        }
        .is-invalid {
            .p-dropdown.p-component {
                border: 1px solid #dc3545 !important;
                transition: all 0.3s;
                &.p-dropdown-open,
                &.p-focus {
                    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
                }
            }
        }
        .p-radiobutton {
            width: 20px;
            height: 20px;
            transition: all 0.3s;
            &.p-radiobutton-checked .p-radiobutton-box {
                border: 1px solid #1570ef;
                background: #eff8ff;
            }
            .p-radiobutton-box {
                width: 20px;
                height: 20px;
                background: #fff;
                border: 1px solid #d0d5dd;
                &.p-focus,
                &:hover {
                    border: 1px solid #1570ef;
                    box-shadow: 0px 0px 0px 3px #eaf3ff, 0px 1px 2px 0px #84caff;
                }
                .p-radiobutton-icon {
                    width: 8px;
                    height: 8px;
                    background: #1570ef;
                }
            }
        }
        .is-invalid .p-radiobutton-box {
            border: 1px solid #dc3545;
        }
        .p-inputgroup .p-inputtext {
            border-radius: 8px 0px 0px 8px !important;
        }
        .hide-dropdown-header .p-dropdown-header {
            display: none;
        }
        &.custom-h-400 {
            .p-dropdown-items-wrapper {
                max-height: 408px !important;
            }
        }

        // hotel-management/payment-info
        &.dropdown-payment-disable {
            .p-dropdown.p-disabled {
                background-color: #f2f4f7;
                opacity: 1;
            }
        }
    }

    .p-dropdown-trigger-icon.pi.pi-chevron-down {
        font-size: 14px !important;
    }

    .custom-phone {
        .is-invalid .p-dropdown.p-component {
            border: none !important;
        }
    }
    .custom-calendar {
        .is-invalid .p-inputtext.p-component {
            border: 1px solid #dc3545;
        }
        .p-inputtext {
            height: 44px;
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            border: 1px solid #d0d5dd;
            border-right: none;

            &:hover {
                border: 1px solid #d0d5dd;
                border-right: none;
            }
    
            &:focus {
                box-shadow: none;
            }
    
            &:enabled:focus {
                border: 1px solid #d0d5dd !important;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
                border-right: none !important;
            }
        }
        .p-button {
            height: 48px;
            border: 1px solid #d0d5dd;
            border-left: none;

            &:enabled:hover {
                background: none;
                color: #D0D5DD;
            }
            &:focus {
                box-shadow: none;
            }
        }
        .p-calendar .p-datepicker {
            top: 50px !important;
            left: 0 !important;
        }
        .p-datepicker{
            padding: 16px;
            padding-bottom: 16px;
        }
        .p-datepicker table td {
            padding: 0 !important;
            font-family: 'Inter' !important;
        }
    
        .p-datepicker table td > span.p-highlight {
            color: white;
            background: #1570EF;
            border-radius: 4px;
        }
    
        .p-datepicker table tbody {
            @apply space-y-1
        }
    
        .p-datepicker table td.p-datepicker-today > span {
            background-color: white;
            color: #1570EF;
            border-radius: 4px;
        }
    
        .p-datepicker table td > span:focus {
            box-shadow: none;
        }
    
        .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
            box-shadow: none;
        }

        .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
            border-radius: 4px;
        }
    
        .p-datepicker .p-datepicker-header {
            border-bottom: none;
        }
    
        .p-datepicker-month,
        .p-datepicker-year{
            color: #344054;
        }
        .p-datepicker-month:enabled:hover,
        .p-datepicker-year:enabled:hover
        {
            color: #1570EF !important;
        }
    
        .p-link:focus{
            box-shadow: none !important;
        }
    
        .p-datepicker-prev:focus{
            box-shadow: none !important;
        }
        
        .p-datepicker-header{
            padding: 0;
        }
        .p-monthpicker-month:not(.p-disabled):focus{
            box-shadow: none !important;
        }
        .p-yearpicker-year:not(.p-disabled):focus {
            box-shadow: none !important;
        }
        .p-yearpicker-year.p-highlight{
            color: #1570EF !important;
        }
        .p-input-icon-right>i {
            top: 25% !important
        }
    }
    .custom-dropdown {
        .p-dropdown .p-dropdown-label.p-placeholder {
            margin: 0;
        }
        .hidden-dropdown {
            .p-overlay {
                display: none;
            }
        }
        .custom-date {
            .p-dropdown-items-wrapper {
                padding: 24px;
                width: 328px;
                height: 264px;
                .p-dropdown-items {
                    display: grid;
                    grid-template-columns: repeat(7, minmax(0, 1fr));
                    border-radius: 8px;
                    .p-element {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .p-dropdown-item {
                            width: 40px;
                            height: 40px !important;
                        }
                    }
                }
            }
        }
    }
    .custom-group {
        .p-inputgroup .p-inputtext {
            border-radius: 8px 0px 0px 8px !important;
            min-height: 44px;
            height: 44px;
            &.is-invalid {
            border: 1px solid #dc3545;
            }
        }
    }
    .checkbox-disabled {
        .p-checkbox-box.p-disabled {
            background-color: #667085;
        }
    }
    .invalid {
        .p-calendar {
            border: 1px solid red;
            border-radius: 8px;
        }
    }
    .custom-radio {
        .p-radiobutton {
            height: 20px !important;
        }
    }
    .custom-disabled {
        .p-inputtext.p-component {
            background-color: #F2F4F7;
            border: 1px solid #D0D5DD;
            color: #344054;
            opacity: 1;
            &:hover {
                border: 1px solid #D0D5DD;
            }
        }
    }
    .custom-switch {
        .p-inputswitch {
            width: 36px;
            height: 20px;

            .p-inputswitch-slider:before {
                width: 16px;
                height: 16px;
                margin-top: -8px;
                left: 2px;
            }
            &.p-inputswitch-checked .p-inputswitch-slider {
                background: #1570EF;
                &:before {
                    transform: translateX(16px);
                }
            }
        }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 300s ease-in-out 0s;
    }
}



// ********************** Rate plans *******************
.rate-plans-section {
    .p-datatable .p-datatable-thead>tr>th {
        font-weight: 500;
        line-height: 20px;
        height: 40px;
        padding: 0 12px;
        background-color: #F2F4F7 !important;
    }
    .p-datatable .p-datatable-tbody>tr>td {
        font-weight: 500;
        line-height: 20px;
        height: 60px;
        padding: 4px 12px;
    }
    .p-inputswitch {
        width: 36px;
        height: 20px;
    }
    .p-inputswitch.p-focus .p-inputswitch-slider {
        box-shadow: none;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch .p-inputswitch-slider {
        background: #F2F4F7;
        &:hover {
            background: #F2F4F7;
        }
    }
    .p-inputswitch .p-inputswitch-slider:before {
        width: 16px;
        height: 16px;
        left: 0.15rem;
        margin-top: -8px;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        transform: translateX(1rem);
    }
    .p-datatable .p-datatable-header {
        padding: 16px 16px;
    }
    .p-datatable .p-datatable-header {
        border: 0 none !important;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        background-color: #fff;
    }
    .p-inputtext {
        min-height: 44px;
        &:hover {
            border-color: #D0D5DD;
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        display: flex;
    }
    .p-multiselect {
        width: 100% !important;
        color: #344054;
        /* body/default/regular */
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        border: 1px solid #D0D5DD;
        &:hover {
            border-color: #D0D5DD;
        }
    }
    .p-multiselect-items-wrapper {
        max-height: 400px !important;
        padding-right: 4px !important;
        padding-left: 4px !important;
    }
    .rate-plans-section .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        border-radius: 8px;
        box-shadow: none;
        &:hover {
            background-color: #F2F4F7;
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 20px;
        padding: 12px !important;
        // checkbox
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: #D0D5DD;
            background-color: #F2F4F7;
        }
        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #1570EF;
            background: #1570EF;
            color: #fff;
        }
        .p-checkbox .p-checkbox-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 4px !important;
        }
        .p-checkbox {
            width: 20px !important;
            height: 20px !important;
        }
        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
            background: #1570EF;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
            border-color: #1570EF;
            box-shadow: none;
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
        background: #fff;
        box-shadow: none;
        border-radius: 8px;
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
        box-shadow: none;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
        background: #F2F4F7;
        border-radius: 8px;
    }
    .p-multiselect-header {
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 20px;
        padding: 12px;
        padding-left: 16px;
        padding-right: 16px;
        background-color: #F2F4F7;
        // checkbox
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: #D0D5DD;
            background-color: #F2F4F7;
        }
        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #1570EF;
            background: #1570EF;
            color: #fff;
        }
        .p-checkbox .p-checkbox-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 4px !important;
        }
        .p-checkbox {
            width: 20px !important;
            height: 20px !important;
        }
        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
            background: #1570EF;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
            border-color: #1570EF;
            box-shadow: none;
        }
        .p-multiselect-close{
            display: none;
        }
        .p-multiselect-filter-container {
            display: flex;
            .p-multiselect-filter-icon{
                right: auto;
                left: 16px;
            }
            .p-inputtext{
                padding-left: 45px;
            }
        }
    }
    .p-multiselect:not(.p-disabled).p-focus {
        //box-shadow: none;
        border: 1px solid #84CAFF !important;
        background: var(--base-white, #FFF);
        box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px #84CAFF;
    }
    .p-paginator {
        padding: 0px;
        height: 52px;
        display: flex;
        justify-content: flex-end;
        padding-right: 16px;
    }
    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-last {
        display: none;
    }
    .p-paginator .p-dropdown {
        display: none;
    }
    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        height: 36px !important;
        min-width: 36px !important;
        background-color: #fff;
        color: #1570EF;
    }
    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        height: 36px;
        min-width: 36px;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
    }
    /* width */
    ::-webkit-scrollbar {
    height: 7px !important;
    }
  
    .p-datatable-wrapper{
        background: #FFF;
    }
    ::-webkit-scrollbar-track-piece{
        background: #EEEEEE;
        margin-left: 12px;
        margin-right: 14px;
        border-radius: 16px;
    }
  }
  
  .rate-plans-modal-section {
    .p-component-overlay {
        background-color: rgba(0, 0, 0.2, 0.6) !important;
    }
    .p-dialog .p-dialog-header {
        border-top-left-radius: 12px !important;
        border-top-right-radius: 12px !important;
        padding-bottom: 2px;
    }
    .p-dialog .p-dialog-content {
        background: #fff !important;
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .p-dialog-header {
        justify-content: flex-start !important;
        position: relative;
    }
    .p-dialog .p-dialog-header-icons {
        position: absolute;
        right: 20px;
    }
    .p-dialog .p-dialog-header .p-dialog-title {
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        font-family: "Inter";
        color: #101828;
    }
    .p-dialog-header-icons {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 24px !important;
        height: 24px !important;
    }
    .pi {
        font-size: 12px;
        color: #667085;
        font-weight: 700;
    }
    .p-dialog-header-close-icon {
        width: 12px !important;
        height: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }
  
  .customize-rate-plan {
  .field-checkbox {
    margin-bottom: 0 !important;
  }
  
  // checkbox
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #D0D5DD;
    background-color: #F2F4F7;
  }
  
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #1570EF;
    background: #1570EF;
    color: #fff;
  }
  
  .p-checkbox .p-checkbox-box {
    width: 20px;
    height: 20px;
    border: 1px solid #D0D5DD;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 4px !important;
  }
  
  .p-checkbox {
    width: 20px !important;
    height: 20px !important;
  }
  
  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: #fff;
    font-weight: 600;
    font-size: 12px;
  }
  
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #1570EF;
  }
  
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border-color: #1570EF;
    box-shadow: none;
  }
  
  .p-progressbar-label {
    display: none !important
  }
  .p-progressbar .p-progressbar-value {
    background-color: #1570EF !important;
    border-radius: 8px;
  }
  
  .p-multiselect {
    width: 100% !important;
    color: #344054;
    /* body/default/regular */
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border: 1px solid #D0D5DD;
  
    &:hover {
      border-color: #D0D5DD;
    }
  }
  
  .p-multiselect-items-wrapper {
    max-height: 400px !important;
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
  
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    border-radius: 8px;
    box-shadow: none;
  
    &:hover {
      background-color: #F2F4F7;
    }
  }
  
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    color: #344054;
    font-family: 'Inter' !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px;
    padding: 12px !important;
  
    
  }
  
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: #fff;
    box-shadow: none;
    border-radius: 8px;
    color: #344054;
    font-family: 'Inter' !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    box-shadow: none;
  }
  
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    background: #F2F4F7;
    border-radius: 8px;
  }
  
  .p-multiselect:not(.p-disabled).p-focus {
    //box-shadow: none;
    border: 1px solid #84CAFF !important;
    background: var(--base-white, #FFF);
    box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px #84CAFF;
  }
  
  // Radio button
  .p-radiobutton .p-radiobutton-box {
    width: 20px;
    height: 20px;
    border: 1px solid #D0D5DD;
  
    &:hover {
      border-color: #1570EF !important;
    }
  }
  
  .p-radiobutton .p-radiobutton-box.p-highlight {
    background: #EFF8FF;
    border-color: #1570EF;
  
    
    &:hover {
     background: #EFF8FF; 
    }
  }
  
  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon  {
    background-color: #1570EF;
    width: 8px;
    height: 8px;
  }
  
  .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #1570EF;
    background: #EFF8FF;
  }
  
  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: none !important;
  }
  
  /// dropdown
  .is-invalid .p-dropdown {
    border: 1px solid #dc3545 !important;
    &.p-focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
    }
  }
  .p-dropdown .p-dropdown-trigger{
    width: 1.25rem !important;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    height: 44px !important;
  }
  
  .p-dropdown-label.p-inputtext {
    &.p-placeholder {
      color: #98a2b3;
      font-weight: 300;
      min-height: 44px;
      font-size: 14px;
    }
    div {
      justify-content: flex-start;
      font-family: Inter, sans-serif;
    }
  }
  .p-dropdown-header {
    display: none;
  }
  .p-dropdown-items-wrapper {
    padding: 4px;
    font-family: Inter, sans-serif;
    max-height: 408px !important;
  
    .p-dropdown-item {
      color: #344054;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-overflow: ellipsis;
      
      &.p-highlight {
        color: #344054;
        background-color: #f2f4f7;
      }
      &:hover {
        background: #f2f4f7 !important;
      }
    }
  }
  }
  

// ********************** List view property management *******************
.list-view-property-management-section {
    .p-datatable>.p-datatable-wrapper {
        border: 1px solid #EAECF0;
        border-radius: 8px;
    }
    .p-datatable .p-datatable-thead>tr>th {
        font-weight: 500;
        line-height: 20px;
        height: 40px;
        padding: 4px 12px;
        background-color: #F9FAFB;
    }
    .p-datatable .p-datatable-tbody>tr>td {
        font-weight: 400;
        line-height: 20px;
        height: 56px;
        padding: 4px 12px;
    }
    .p-datatable .p-datatable-header {
        padding: 16px 16px;
    }
    .p-datatable .p-datatable-header {
        border: 1px solid #EAECF0;
        border: 1px 1px 0 1px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        background-color: #fff;
    }
    .p-datatable .p-datatable-tbody>tr {
        &:hover {
            background-color: #F9FAFB !important;
        }
    }
    .p-datatable .p-datatable-tbody>tr>td {
        border: 1px solid #EAECF0;
        border-width: 0 0 1px 0;
    }
    .p-datatable .p-datatable-thead>tr>th {
        border: 1px solid #EAECF0;
        border-width: 0 0 1px 0;
    }
  }

  .p-datatable .p-datatable-tbody > tr > td {
    border: 1px solid #EAECF0;
    border-width: 0 0 1px 0;
  }

  .p-datatable .p-datatable-thead > tr > th {
    border: 1px solid #EAECF0;
    border-width: 0 0 1px 0;
  }

// ---------------------- promotion -----------------------
.promotion-tab .p-tabview-panels {
    //height: calc(100vh - 150px);
    //overflow-y: auto;
    padding-top: 0px;
}

.promotion-tab .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #1570EF !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 20px;
    background: none !important
}
.promotion-tab .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: none !important;
    color: #667085 !important;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.promotion-tab .p-tabview-nav li {
    height: 31px;
}

.promotion-tab .p-tabview-nav li:not(.p-highlight):not(.p-tabview-ink-bar) {
    border-bottom: solid 1px;
    @apply border-palette-gray-200
}

.promotion-tab .p-highlight.ng-star-inserted {
    border-bottom: 2px solid #1570EF;
}

.promotion-tab .p-tabview .p-tabview-nav {
    padding-top: 16px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #FFF;
    border: 1px solid #eaecf0;
    border-bottom: 0 none !important;
}

.promotion-tab .grid {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.promotion-tab .p-tabview .p-tabview-panels {
    margin-top: 0px !important;
}

.promotion-tab .p-tabview .p-tabview-panels {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
.promotion-tab .p-tabview-nav-container{
    padding-right: 24px !important;
}

.promotion-tab .p-tabview-nav{
    display: flex;
    flex-wrap: wrap;
}
.promotion-section .p-tabview .p-tabview-nav li .p-tabview-nav-link{
    padding: 0.25rem 0.75rem 0.25rem 0.75rem !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: 100%;
    height: 100%;
}

.promotion-section {
    .p-inputtext.p-component{
        &:hover {
            border: 1px solid #84caff !important;
        }
    }
    .p-inputtext:enabled:hover{
        border: 1px solid #84caff !important;
        } 

    .p-datatable .p-datatable-thead>tr>th {
        font-weight: 500;
        line-height: 20px;
        height: 40px;
        padding: 0 12px;
        background-color: #F2F4F7 !important;
    }
    .p-datatable .p-datatable-tbody>tr>td {
        font-weight: 500;
        line-height: 20px;
        height: 60px;
        padding: 4px 12px;
    }
    .p-inputswitch {
        width: 36px;
        height: 20px;
    }
    .p-inputswitch.p-focus .p-inputswitch-slider {
        box-shadow: none;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch .p-inputswitch-slider {
        background: #F2F4F7;
        &:hover {
            background: #F2F4F7;
        }
    }
    .p-inputswitch .p-inputswitch-slider:before {
        width: 16px;
        height: 16px;
        left: 0.15rem;
        margin-top: -8px;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        transform: translateX(1rem);
    }
    .p-datatable .p-datatable-header {
        padding: 16px 16px;
    }
    .p-datatable .p-datatable-header {
        border: 1px solid #EAECF0;
        border: 1px 1px 0 1px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        background-color: #fff;
    }
    .p-inputtext {
        min-height: 44px;
        &:hover {
            border-color: #D0D5DD;
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        display: flex;
    }
    .p-multiselect {
        width: 100% !important;
        color: #344054;
        /* body/default/regular */
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        border: 1px solid #D0D5DD;
        &:hover {
            border-color: #D0D5DD;
        }
    }
    .p-multiselect-items-wrapper {
        max-height: 400px !important;
        padding-right: 4px !important;
        padding-left: 4px !important;
    }
    .rate-plans-section .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        border-radius: 8px;
        box-shadow: none;
        &:hover {
            background-color: #F2F4F7;
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 20px;
        padding: 12px !important;
        // checkbox
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: #D0D5DD;
            background-color: #F2F4F7;
        }
        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #1570EF;
            background: #1570EF;
            color: #fff;
        }
        .p-checkbox .p-checkbox-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 4px !important;
        }
        .p-checkbox {
            width: 20px !important;
            height: 20px !important;
        }
        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
            background: #1570EF;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
            border-color: #1570EF;
            box-shadow: none;
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
        background: #fff;
        box-shadow: none;
        border-radius: 8px;
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
        box-shadow: none;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
        background: #F2F4F7;
        border-radius: 8px;
    }
    .p-multiselect-header {
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 20px;
        padding: 12px;
        padding-left: 16px;
        padding-right: 16px;
        background-color: #F2F4F7;
        // checkbox
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: #D0D5DD;
            background-color: #F2F4F7;
        }
        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #1570EF;
            background: #1570EF;
            color: #fff;
        }
        .p-checkbox .p-checkbox-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 4px !important;
        }
        .p-checkbox {
            width: 20px !important;
            height: 20px !important;
        }
        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
            background: #1570EF;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
            border-color: #1570EF;
            box-shadow: none;
        }
        .p-multiselect-close{
            display: none;
        }
        .p-multiselect-filter-container {
            display: flex;
           // justify-content: space-between !important;
           // flex-direction: row-reverse !important;
            .p-multiselect-filter-icon{
                right: auto;
                left: 16px;
            }
            .p-inputtext{
                padding-left: 45px;
            }
        }
    }
    .p-multiselect:not(.p-disabled).p-focus {
        //box-shadow: none;
        border: 1px solid #84CAFF !important;
        background: var(--base-white, #FFF);
        box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px #84CAFF;
    }
    .p-paginator {
        padding: 0px;
        height: 52px;
        display: flex;
        justify-content: flex-end;
        padding-right: 16px;
    }
    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-last {
        display: none;
    }
    .p-paginator .p-dropdown {
        display: none;
    }
    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        height: 36px !important;
        min-width: 36px !important;
        background-color: #fff;
        color: #1570EF;
    }
    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        height: 36px;
        min-width: 36px;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
    }
    .form-input {
        font-size: 14px;
        border: 1px solid #d0d5dd;
        height: 44px;
        min-height: 44px;
        font-family: 'Inter';
        outline: none;
        color: #344054;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      
        &:focus {
          border: 1px solid #84caff;
          box-shadow: 0px 0px 0px 4px #eaf3ff, 0px 1px 2px 0px #84caff;
        }
      
        &:hover {
          border: 1px solid #84caff !important;
        }

        &:enabled:hover  {
            border: 1px solid #84caff !important;
          }
        
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        
        input[type="number"] {
          appearance: textfield;
        }
        .p-calendar{
            width: 100% !important;
        }
    }
    .p-input-icon-right>i {
        top: 0% !important
    }
    /* width */
    ::-webkit-scrollbar {
    height: 7px !important;
    }
    .p-datatable-wrapper{
        background: #FFF;
    }
    ::-webkit-scrollbar-track-piece{
        background: #EEEEEE;
        margin-left: 12px;
        margin-right: 14px;
        border-radius: 16px;
    }
}

.radio-button-promotion{
    .p-radiobutton .p-radiobutton-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        &:hover {
            border-color: #1570EF !important;
        }
    }
    .p-radiobutton .p-radiobutton-box.p-highlight {
        background: #EFF8FF;
        border-color: #1570EF;
        &:hover {
            background: #EFF8FF;
        }
    }
    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background-color: #1570EF;
        width: 8px;
        height: 8px;
    }
    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: #1570EF !important;
        background: #EFF8FF !important;
    }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
        border-color: #1570EF;
        background: #EFF8FF;
    }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }
}

.calendar-filter-discount {
    .p-slider:not(.p-disabled) .p-slider-handle:hover{
        border-color: #1570EF !important;
        background: #1570EF !important;
    }
    .p-slider .p-slider-handle{
        height: 24px;
        width: 24px !important;
        border-color: #1570EF !important;
        top: -10% !important;
    }
    .p-slider .p-slider-range{
        align-items: center !important;
        height: 8px !important;
        background: #1570EF !important;
    }
    .p-slider-horizontal .p-slider-range{
        top: -2px !important;
    }
}

.calendar-filter-from {
    .p-calendar .p-datepicker {
        top: 50px !important;
        left: -131px !important;
    }
    .p-inputtext{
        height: 44px;
        min-height: 44px;
        width: 100% !important;
        border: 1px solid #d0d5dd;
        font-family: Inter, sans-serif;
        font-size: 14px;
    }
    .p-inputtext:enabled:hover{
        border: 1px solid #84caff;
    }
    .p-inputtext:enabled:focus{
        height: 44px;
        min-height: 44px;
        width: 100% !important;
        border: 1px solid #84caff;
        font-weight: 400;
    }
    .p-calendar{
        width: 100% !important;
    }
}

.calendar-filter-to {
    .p-calendar .p-datepicker {
        top: 50px !important;
        left: -174px !important;
    }
    .p-inputtext{
        height: 44px;
        min-height: 44px;
        width: 100% !important;
        border: 1px solid #d0d5dd;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 400;
    }
    .p-inputtext:enabled:hover{
        border: 1px solid #84caff;
    }
    .p-inputtext:enabled:focus{
        height: 44px;
        min-height: 44px;
        width: 100% !important;
        border: 1px solid #84caff;
    }
    .p-calendar{
        width: 100% !important;
    }
}

.calendar-promotions {
    .p-datepicker{
        padding: 16px;
        padding-bottom: 16px;
    }
    .p-datepicker table td {
        padding: 0 !important;
        font-family: 'Inter' !important;
    }

    .p-datepicker table td > span.p-highlight {
        color: white !important;
        background: #1570EF !important;
    }

    .p-datepicker table tbody {
        @apply space-y-1
    }

    .p-datepicker table td.p-datepicker-today > span {
        background-color: white;
        color: #1570EF;
    }

    .p-datepicker table td > span:focus {
        box-shadow: none;
    }

    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
        box-shadow: none;
    }

    .p-datepicker .p-datepicker-header {
        border-bottom: none;
    }

    .p-datepicker-month,
    .p-datepicker-year{
        color: #344054;
    }
    .p-datepicker-month:enabled:hover,
    .p-datepicker-year:enabled:hover
    {
        color: #1570EF !important;
    }

    .p-link:focus{
        box-shadow: none !important;
    }

    .p-datepicker-prev:focus{
        box-shadow: none !important;
    }
    
    .p-datepicker-header{
        padding: 0;
    }
    .p-monthpicker-month:not(.p-disabled):focus{
        box-shadow: none !important;
    }
    .p-yearpicker-year:not(.p-disabled):focus {
        box-shadow: none !important;
    }
    .p-yearpicker-year.p-highlight{
        color: #1570EF !important;
    }
    .p-input-icon-right>i {
        top: 25% !important
    }
}



.promotions-modal-section {
    .p-component-overlay {
        background-color: rgba(0, 0, 0.2, 0.6) !important;
    }
    .p-dialog .p-dialog-header {
        border-top-left-radius: 12px !important;
        border-top-right-radius: 12px !important;
        padding-bottom: 2px;
    }
    .p-dialog .p-dialog-content {
        background: #fff !important;
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .p-dialog-header {
        justify-content: flex-start !important;
        position: relative;
    }
    .p-dialog .p-dialog-header-icons {
        position: absolute;
        right: 20px;
    }
    .p-dialog .p-dialog-header .p-dialog-title {
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        font-family: "Inter";
        color: #101828;
    }
    .p-dialog-header-icons {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 24px !important;
        height: 24px !important;
    }
    .pi {
        font-size: 12px;
        color: #667085;
        font-weight: 700;
    }
    .p-dialog-header-close-icon {
        width: 12px !important;
        height: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

// ********************** Promotion stay date *******************
.calendar-stay-dates {
    .p-datepicker {
        padding: 12px;
    }

    .p-datepicker table td > span {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
        padding-right: 12px;
    }

    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
        padding-left: 12px;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-right: 0;
        padding-right: 0;
    }

    .p-datepicker table th > span {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    .p-datepicker table td {
        padding: 0 !important;
    }

    .p-datepicker table td > span.p-highlight {
        color: black;
        background: none;
    }

    .p-datepicker table tbody {
        @apply space-y-1
    }

    .p-datepicker table td.p-datepicker-today > span {
        background-color: white;
        color: #1570EF;
    }

    .p-datepicker table td > span:focus {
        box-shadow: none;
    }

    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
        box-shadow: none;
    }

    .p-datepicker .p-datepicker-header {
        border-bottom: none;
    }
}

.input-calendar-stay-dates {
    .p-inputtext {
        min-height: 0;
        height: 44px;
    }

    .p-inputtext:enabled:hover {
        border-color: #84CAFF;
    }

    .p-input-icon-right>i {
        top: 45%;
    }
}

.input-calendar-bookable {
    .p-datepicker {
        display: flex;
    }

    .p-inputtext {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .p-calendar .p-datepicker {
        top: -395px !important;
        left: -86% !important;
    }

    .p-datepicker table td > span {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        pointer-events: none;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-right: 0;
        padding-right: 0;
        pointer-events: none;
    }

    .p-datepicker table th > span {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    .p-calendar {
        width: 100%;
    }

    .p-timepicker {
        display: none;
    }
  
    .time-picker-bookable {
        width: 200px;
    }

    .p-calendar-w-btn .p-inputtext {
        min-height: 0;
        height: 44px;
        border-bottom: 1px solid #D0D5DD;
        border-top: 1px solid #D0D5DD;
        border-right: none;
        border-left: 1px solid #D0D5DD;
    }

    .p-calendar-w-btn .p-datepicker-trigger {
        height: 44px;
        border-bottom: 1px solid #D0D5DD;
        border-top: 1px solid #D0D5DD;
        border-right: 1px solid #D0D5DD;
        border-left: none;
    }

    .p-button:enabled:hover {
        background: none;
        color: #D0D5DD;
    }

    .p-button:focus {
        box-shadow: none;
    }

    .p-datepicker table td {
        padding: 1px;
    }

    .p-datepicker table td.p-datepicker-today > span {
        background-color: #fff;
        color: #1570EF;
    }

    .p-datepicker table td > span.p-highlight {
        border: 1px solid #1570EF;
        background-color: #1570EF;
        color: white;
    }

    .p-inputtext:focus {
        box-shadow: none;
    }

    .p-inputtext:enabled:focus {
        border: 1px solid #D0D5DD !important;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: none !important;
    }
}
.custom-button-badge {
    .p-button-text{
        padding: 0;
    }

    .p-button-label{
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        color: #667085;
        padding: var(--Spacing-2, 8px) var(--Spacing-3, 12px);
        border-radius: var(--Border-Radius-4, 4px);
    }
    &.clicked {
        .p-button-label{
            background-color: #EFF8FF; 
            color: #1570EF;
        }
        .p-button .p-badge{
            background-color: #EFF8FF; 
            color: #1570EF;
        }
    }
    .p-button .p-badge{
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        color: #344054;
        border-radius: var(--Border-Radius-Full, 1000px);
        background: var(--Color-Background-System-Secondary, #F2F4F7);
    }

}
.manage-reservations {
    app-tag {
        display: inline-table;
        padding: 0.5rem 1.5rem; 
        .error{
            color: #344054;
            background-color: #F2F4F7;
        }
        .info{
            color: #1570EF;
            background-color: #EFF8FF;
        }
        .warning{
            color: #DC6803;
            background-color: #FFFAEB;
        }
        .success{
            color: #039855;
            background-color: #ECFDF3;
        }
        .normal {
            color: #6938EF;
            background-color: #F4F3FF;
        }
      }
    .p-datatable-wrapper{
        background: #FFF;
    }
    ::-webkit-scrollbar-track-piece{
        background: #EEEEEE;
        margin-left: 12px;
        margin-right: 14px;
        border-radius: 16px;
    }
}
body.popup-open {
    overflow: hidden;
}

.taxes-and-fees {
    .form-input {
        font-size: 14px;
        border: 1px solid #d0d5dd;
        height: 44px;
        min-height: 44px;
        font-family: 'Inter';
        outline: none;
        color: #344054;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 8px;

        &:focus {
        border: 1px solid #84caff;
        box-shadow: 0px 0px 0px 4px #eaf3ff, 0px 1px 2px 0px #84caff;
        }

        &.is-invalid {
        border: 1px solid #dc3545 !important;

        &:focus {
            border: 1px solid #dc3545 !important;
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
        }
        }

        &:hover {
        border: 1px solid #84caff;
        }  
    }

    .p-inputtext {
        min-height: 44px;
        font-family: 'Inter';
        font-size: 14px !important;
    }

    .p-inputgroup-addon {
        border-left: 0px !important;
    }
     //dropdown
     .p-dropdown {
        box-shadow: none !important;
        border: none !important;
        border-left: 0px;
        min-height: 42px;
        height: 42px;
        width: 100% !important;
        .p-inputgroup-addon {
            border-left: 1px !important;
        }
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }

    .p-dropdown .p-dropdown-trigger {
      margin-right: 6px;
    }
    .p-dropdown:not(.p-disabled).p-focus {
      border: none !important;
      //border-radius: none !important;
    }
      
    .p-dropdown,
    .p-dropdown-label .p-inputtext {
        align-items: center;
        width: 160px;
        min-height: 42px;
        height: 42px;
        font-size: 14px !important;
        font-family: 'Inter';
        outline: none;
        color: #344054;
      
        &.p-placeholder {
          font-size: 14px;
          color: #98a2b3;
          font-weight: 400;
          min-height: 42px;
          height: 42px;
          border: none;
        }
    }

    .p-dropdown .p-dropdown-label.p-placeholder {
        font-size: 14px;
        color: #98A2B3 !important;
        font-weight: 400;
        min-height: 42px;
        height: 42px;
        border: none;
    }
    
    .p-dropdown-panel{
        border: 1px solid #D0D5DD;
    }
    .input-charge {
        font-size: 14px;
        border: 1px solid #d0d5dd;
        height: 44px;
        min-height: 44px;
        font-family: 'Inter';
        outline: none;
        color: #344054;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        &:hover {
          border: 1px solid #84caff;
        }
        &:focus {
          border: 1px solid #84caff;
          box-shadow: 0px 0px 0px 4px #eaf3ff, 0px 1px 2px 0px #84caff;
        }
        &.p-inputtext:enabled:focus{
            border-radius: 0;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border: 1px solid #84caff;
            box-shadow: 0px 0px 0px 4px #eaf3ff, 0px 1px 2px 0px #84caff;
        }
        &.p-inputtext:enabled:hover{
          border: 1px solid #84caff;
        }
        .p-dropdown{
          border-bottom-left-radius: 0px !important;
          border-top-left-radius: 0px !important;
          &:hover {
            border-left: 0 none;
          }
        }
        &.is-invalid {
          border: 1px solid #dc3545 !important;
          &:focus {
            border: 1px solid #dc3545 !important;
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          }
          &.p-inputtext:enabled:focus{
            border-radius: 0;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border: 1px solid #dc3545;
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          }
        }
    }
    .form-disable {
        font-size: 14px;
        border-radius: 8px;
        border: 1px solid #d0d5dd;
        height: 44px;
        min-height: 44px;
        font-family: 'Inter';
        outline: none;
        color: #344054;
        background-color: #F2F4F7 !important;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        &.p-component:disabled {
          opacity: 1 !important;
        }
        .p-inputgroup-addon {
          border-left: 1px !important;
        }
        .p-dropdown{
          width: 100% !important;
          border: 1 #d0d5dd !important;
          box-shadow: none;
          min-height: 42px;
          height: 42px;
          background-color: #F2F4F7 !important;
          &.p-disabled {
              opacity: 1 !important;
          }
        }
        &:hover {
          border: 1px solid #d0d5dd !important;
        }
    }
    
    .disable-charge {
        font-size: 14px;
        border: 1px solid #d0d5dd;
        height: 44px;
        min-height: 44px;
        font-family: 'Inter';
        outline: none;
        color: #344054;
        background-color: #F2F4F7;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        &.p-component:disabled {
          opacity: 1 !important;
        }
        &:hover {
          border-color: #d0d5dd !important;
        }
    
        .p-dropdown{
          width: 100% !important;
          border: 1 #d0d5dd !important;
          box-shadow: none;
          min-height: 42px;
          height: 42px;
          background-color: #F2F4F7 !important;
          &.p-disabled {
            opacity: 1 !important; 
          }
        }
        .p-dropdown:not(.p-disabled):hover {
          border-color:#d0d5dd !important;
        } 
    }
}


.flight-search-bar{
    // radiobutton
    .p-radiobutton .p-radiobutton-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        &:hover {
            border-color: #1570EF !important;
        }
    }
    .p-radiobutton .p-radiobutton-box.p-highlight {
        background: #EFF8FF;
        border-color: #1570EF;
        &:hover {
            background: #EFF8FF;
        }
    }
    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background-color: #1570EF;
        width: 8px;
        height: 8px;
    }
    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: #1570EF !important;
        background: #EFF8FF !important;
    }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
        border-color: #1570EF;
        background: #EFF8FF;
    }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }
    // check box
    .form-input {
        font-size: 14px;
        height: 44px;
        min-height: 44px;
        font-family: 'Inter';
        outline: none;
        color: #344054;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 8px;
    }
    .form-input:has(.box-input:focus) {
        border: 1px solid #84caff;
        box-shadow: 0px 0px 0px 4px #eaf3ff, 0px 1px 2px 0px #84caff;
    }

    .form-input:has(.box-input:hover) {
        border: 1px solid #84caff;
    }

    .box-input {
        font-size: 14px;
        width: 100%;
        font-family: 'Inter';
        outline: none;
        color: #344054;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 8px;

        &:focus {
        border: none;
        box-shadow: none;
        }

        &.is-invalid {
        border: none;
        }
    }
}


// ********************** Rate plans *******************
.user-management-section {
    .p-datatable .p-datatable-thead>tr>th {
        font-weight: 500;
        line-height: 20px;
        height: 40px;
        padding: 0 12px;
        background-color: #F2F4F7;
    }
    .p-datatable .p-datatable-tbody>tr>td {
        font-weight: 500;
        line-height: 20px;
        height: 60px;
        padding: 4px 12px;
    }
    .p-inputswitch {
        width: 36px;
        height: 20px;
    }
    .p-inputswitch.p-focus .p-inputswitch-slider {
        box-shadow: none;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch .p-inputswitch-slider {
        background: #F2F4F7;
        &:hover {
            background: #F2F4F7;
        }
    }
    .p-inputswitch .p-inputswitch-slider:before {
        width: 16px;
        height: 16px;
        left: 0.15rem;
        margin-top: -8px;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        transform: translateX(1rem);
    }
    .p-datatable .p-datatable-header {
        padding: 16px 16px;
    }
    .p-datatable .p-datatable-header {
        border: 1px solid #EAECF0;
        border: 1px 1px 0 1px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        background-color: #fff !important;
    }
    .p-inputtext {
        min-height: 44px;
        &:hover {
            border-color: #D0D5DD;
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        display: flex;
    }
    .p-multiselect {
        width: 100% !important;
        color: #344054;
        /* body/default/regular */
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        height: auto;
        min-height: 44px;
        border: 1px solid #D0D5DD;
        &:hover {
            border-color: #84caff;
        }
    }
    .p-multiselect-items-wrapper {
        max-height: 400px !important;
        padding-right: 4px !important;
        padding-left: 4px !important;
    }
    .rate-plans-section .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        border-radius: 8px;
        box-shadow: none;
        &:hover {
            background-color: #F2F4F7;
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 20px;
        padding: 12px !important;
        height: 44px;
        min-height: 44px;
        // checkbox
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: #D0D5DD;
            background-color: #F2F4F7;
        }
        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #1570EF;
            background: #1570EF;
            color: #fff;
        }
        .p-checkbox .p-checkbox-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 4px !important;
        }
        .p-checkbox {
            width: 20px !important;
            height: 20px !important;
        }
        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
            background: #1570EF;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
            border-color: #1570EF;
            box-shadow: none;
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
        background: #fff;
        box-shadow: none;
        border-radius: 8px;
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        height: 44px;
        min-height: 44px;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
        box-shadow: none;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
        background: #F2F4F7;
        border-radius: 8px;
    }
    .p-multiselect-header {
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 20px;
        padding: 12px;
        padding-left: 16px;
        padding-right: 16px;
        background-color: #F2F4F7;
        // checkbox
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: #D0D5DD;
            background-color: #F2F4F7;
        }
        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #1570EF;
            background: #1570EF;
            color: #fff;
        }
        .p-checkbox .p-checkbox-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 4px !important;
        }
        .p-checkbox {
            width: 20px !important;
            height: 20px !important;
        }
        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
            background: #1570EF;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
            border-color: #1570EF;
            box-shadow: none;
        }
        .p-multiselect-close{
            display: none;
        }
        .p-multiselect-filter-container {
            display: flex;
            .p-multiselect-filter-icon{
                right: auto;
                left: 16px;
            }
            .p-inputtext{
                padding-left: 45px;
            }
        }
    }
    .p-multiselect:not(.p-disabled).p-focus {
        //box-shadow: none;
        border: 1px solid #84CAFF !important;
        background: var(--base-white, #FFF);
        box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px #84CAFF;
    }
    .p-multiselect-label{
        white-space: normal;
    }
    .p-multiselect .p-multiselect-label.p-placeholder{
        color: #98A2B3 !important;
    }
    .p-paginator {
        padding: 0px;
        height: 52px;
        display: flex;
        justify-content: flex-end;
        padding-right: 16px;
    }
    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-last {
        display: none;
    }
    .p-paginator .p-dropdown {
        display: none;
    }
    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        height: 36px !important;
        min-width: 36px !important;
        background-color: #fff;
        color: #1570EF;
    }
    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        height: 36px;
        min-width: 36px;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
    }
    .p-dropdown{
        display: flex;
        align-items: center;
        height: 44px;
        min-height: 44px;
        width: 100% !important;
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px !important;
        font-style: normal;
        line-height: 20px;
        font-weight: 500 !important;
        outline: none;
        padding-left: 4px;
        .p-dropdown-trigger {
            width: unset !important;
            padding: 10px 16px 10px 0;
        }
        .p-dropdown-label{
            font-size: 14px !important;
            font-family: 'Inter';
            outline: none;
            color: #344054;
            font-weight: 500 !important;
            &.p-placeholder{
                color: #98A2B3 !important;
            }
        }
    }
    .p-dropdown-panel {
        border: 1px solid #D0D5DD;
        font-size: 14px !important;
        font-family: 'Inter';
        outline: none;
        .p-dropdown-items{
            margin-left: 4px;
            margin-right: 4px;
            .p-dropdown-item{
                font-weight: 500 !important;
            }
            .p-dropdown-item.p-highlight,
            .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover{
                background-color: #F2F4F7;
                color: #344054;
                border-radius: 8px;
                font-weight: 500 !important;
            }
        }
    }
    .p-dropdown:not(.p-disabled):hover {
        border-color: #84caff;
    }
    /* width */
    ::-webkit-scrollbar {
        height: 7px !important;
    }
    
    .p-datatable-wrapper{
        background: #FFF;
    }
    ::-webkit-scrollbar-track-piece{
        background: #EEEEEE;
        margin-left: 12px;
        margin-right: 14px;
        border-radius: 16px;
    }
}
.invalid-user-management{
    .p-dropdown{
        border: 1px solid #dc3545 !important;
    }
    .p-multiselect{
        border: 1px solid #dc3545 !important;
    }
}

.user-modal-section {
    .p-component-overlay {
        background-color: rgba(0, 0, 0.2, 0.6) !important;
    }
    .p-dialog .p-dialog-header {
        border-top-left-radius: 12px !important;
        border-top-right-radius: 12px !important;
        padding-bottom: 2px;
    }
    .p-dialog .p-dialog-content {
        background: #fff !important;
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .p-dialog-header {
        justify-content: flex-start !important;
        position: relative;
    }
    .p-dialog .p-dialog-header-icons {
        position: absolute;
        right: 20px;
    }
    .p-dialog .p-dialog-header .p-dialog-title {
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        font-family: "Inter";
        color: #101828;
    }
    .p-dialog-header-icons {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 24px !important;
        height: 24px !important;
    }
    .pi {
        font-size: 12px;
        color: #667085;
        font-weight: 700;
    }
    .p-dialog-header-close-icon {
        width: 12px !important;
        height: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

// ********************** Property Policies *******************
.property-policies {
    .form-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #344054;
        &.form-require {
            color: #d92d20;
        }
        }
      
    .form-input {
        font-size: 14px;
        border: 1px solid #d0d5dd;
        height: 44px;
        min-height: 44px;
        font-family: 'Inter';
        outline: none;
        color: #344054;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 8px;

        &:focus {
        border: 1px solid #84caff;
        box-shadow: 0px 0px 0px 4px #eaf3ff, 0px 1px 2px 0px #84caff;
        }

        &.is-invalid {
        border: 1px solid #dc3545 !important;

        &:focus {
            border: 1px solid #dc3545 !important;
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
        }
        }

        &:hover {
        border: 1px solid #84caff;
        }  
    }

    .form-disable {
        font-size: 14px;
        border-radius: 8px;
        border: 1px solid #d0d5dd;
        height: 44px;
        min-height: 44px;
        font-family: 'Inter';
        outline: none;
        color: #344054;
        background-color: #F2F4F7;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        &.p-component:disabled {
          opacity: 1 !important;
        }
        .p-inputgroup-addon {
          border-left: 1px !important;
        }
        .p-dropdown{
          width: 100% !important;
          border: 1 #d0d5dd !important;
          box-shadow: none;
          min-height: 42px;
          height: 42px;
          background-color: #F2F4F7;
          &.p-disabled {
              opacity: 1 !important;
          }
        }
        &:hover {
          border: 1px solid #d0d5dd !important;
          }
      }
      
    .read-only {
        border-radius: 8px;
        border: 1px solid #d0d5dd;
        padding: 12px 16px;
        height: 44px;
        font-size: 14px;
        line-height: 20px;
        outline: none;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        color: #344054 !important;
        cursor:default;
    }
      
    .input-time {
        padding-left: 40px !important;
    }
      
    .p-inputtext {
        min-height: 44px;
        font-family: 'Inter';
        font-size: 14px !important;
    }
    
    .p-inputgroup {
        border-radius: 0;
    }

    .p-inputgroup-addon {
        border-left: 0px !important;
    }
    
    //dropdown
    .p-dropdown {
        box-shadow: none !important;
        border: none !important;
        border-left: 0px;
        min-height: 42px;
        height: 42px;
        width: 100% !important;
        .p-inputgroup-addon {
            border-left: 1px !important;
        }
    }

    .p-dropdown .p-dropdown-trigger {
      margin-right: 6px;
    }
    .p-dropdown:not(.p-disabled).p-focus {
      border: none !important;
      border-radius: none !important;
    }
      
    .p-dropdown,
    .p-dropdown-label .p-inputtext {
        align-items: center;
        width: 160px;
        min-height: 42px;
        height: 42px;
        font-size: 14px !important;
        font-family: 'Inter';
        outline: none;
        color: #344054;
      
        &.p-placeholder {
          font-size: 14px;
          color: #98a2b3;
          font-weight: 400;
          min-height: 42px;
          height: 42px;
          border: none;
        }
    }

    .p-dropdown .p-dropdown-label.p-placeholder {
        font-size: 14px;
        color: #98A2B3 !important;
        font-weight: 400;
        min-height: 42px;
        height: 42px;
        border: none;
    }
    
    .p-dropdown-panel{
        border: 1px solid #D0D5DD;
    }

    //switch control
    .small-input-switch {
      .p-inputswitch {
        height: 1.25rem;
        width: 2.25rem;
        &.p-inputswitch-checked {
            .p-inputswitch-slider::before {
              transform: translateX(0.9rem);
            }
            .p-inputswitch-slider {
              background: #1570EF;
            }
        }
          &.p-inputswitch-checked:not(.p-disabled):hover {
          .p-inputswitch-slider {
            background: #1570EF;
          } 
        }
        &.p-focus .p-inputswitch-slider {
          box-shadow: 0 0 0 0.2rem #d1e9ff;
        }     
        .p-inputswitch-slider::before {
            width: 0.9rem;
            height: 0.9rem;
            margin-top: -0.45rem;
        }
      }
    }
    
    //input number
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    input[type="number"] {
        appearance: textfield;
    }

    //text area
    textarea {
        resize: none;
    }
    .text-disable{
        color: #A2D4FB;
    }      

    // Radio button
    .p-radiobutton .p-radiobutton-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        &:hover {
            border-color: #1570EF !important;
        }
    }
    .p-radiobutton .p-radiobutton-box.p-highlight {
        background: #EFF8FF;
        border-color: #1570EF;
        &:hover {
            background: #EFF8FF;
        }
    }
    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background-color: #1570EF;
        width: 8px;
        height: 8px;
    }
    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: #1570EF;
        background: #EFF8FF;
    }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }

    // checkbox
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: #1570EF;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #1570EF;
        background: #1570EF;
        color: #fff;
    }
    .p-checkbox .p-checkbox-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 4px !important;
    }

    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        color: #fff;
        font-weight: 600;
        font-size: 12px;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background: #1570EF;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        border-color: #1570EF;
        box-shadow: none;
    }

    .pay-ment .p-checkbox .p-checkbox-box.p-highlight{
        background-color: #B2DDFF !important;
        border-color: #B2DDFF !important;
    }
}


// ****************** Account balance ************************
// ****************** Withdrawal ************************
p-dialog.withdrawal-dialog {
    .p-dialog {
        max-height: 90% !important;
    }
    .p-dialog-content {
        background-color: white !important;

        .p-inputtext {
            min-height: unset;
            height: 44px;
        }
    }
    p-inputnumber {
        span {
            width: 100% !important;
        }
    }
    textarea {
        min-height: 96px !important;
    }
}

p-table.balance-histories-table {
    .p-datatable {
        .p-datatable-wrapper {
            border: 1px solid #EAECF0;
            border-radius: 8px;
        }
    }
}

p-calendar.calendar-dashboard {
    .p-datepicker {
        .p-datepicker-header {
            height: 65px;
        }

        table {
            td {
                padding: unset;

                span {
                    border-radius: unset;
                    color: #344054;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    box-shadow: none;
                    width: 2.6rem;
                }
            }

            td.p-datepicker-today {
                span {
                    border-color: #1570EF;
                    background-color: unset;
                    border-radius: 4px;
                    color: #1570EF;
                }
            }
        }
    }

    .p-monthpicker {
        .p-monthpicker-month {
            width: 22%;
            height: 32px;
            color: #344054;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin: 8px 4px;
            box-shadow: unset !important;

            &.p-highlight {
                border: 1px solid #1570EF;
                background-color: unset;
                border-radius: 4px;
                color: #1570EF;
                box-shadow: unset !important;
            }
        }

    }

    .p-yearpicker {
        .p-yearpicker-year {
            width: 22%;
            height: 32px;
            color: #344054;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin: 8px 4px;
            box-shadow: unset !important;

            &.p-highlight {
                border: 1px solid #1570EF;
                background-color: unset;
                border-radius: 4px;
                color: #1570EF;
                box-shadow: unset !important;
            }
        }

    }
}
// -------------------------------------Payment information --------------------------
.paymentInfo .p-overlay {
    width: 100%;
}

.paymentInfo {
    // Radio button
    .p-radiobutton .p-radiobutton-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        &:hover {
            border-color: #1570EF !important;
        }
    }
    .p-radiobutton .p-radiobutton-box.p-highlight {
        background: #EFF8FF;
        border-color: #1570EF;
        &:hover {
            background: #EFF8FF;
        }
    }
    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background-color: #1570EF;
        width: 8px;
        height: 8px;
    }
    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: #1570EF;
        background: #EFF8FF;
    }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }
    // input text
    .p-inputtext:not(.p-inputtext::placeholder) {
        text-transform: uppercase;
    }
}

@media screen and (min-width: 1024px) {
    .paymentInfo .p-overlay {
        min-width: 245px !important;
    }
}

// **********************************************************
// ****************** Detail hotel - About ************************
.hotel-detail-about.approval-active {
    min-height: 200px;
    padding: 12px 16px;
}

.hotel-detail-about {
    .p-editor-container .p-editor-toolbar.ql-snow {
        display: none;
        border: none;
    }

    .ql-container.ql-snow {
        border: none;
    }

    .p-editor-container .p-editor-content.ql-snow {
        display: contents;
    }

    .ql-editor {
        overflow-y: hidden;
        padding: 0;
    }

    .p-editor-container .p-editor-content .ql-editor {
        font-family: 'Inter', 'sans-serif';
        font-size: 16px;
        color: #344054;
        border: 0 solid;
        border-radius: 8px;
    }
}

// ************ Hotel management: Contract Agreement ************
// ************************ Upload file  ************************
.upload-file-section {
    &.disable-background {
        background-color: #f2f4f7;
    }
}


// *********** Review Management ************************
.review-management {
    margin-left: auto;
    margin-right: auto;
    font-family: 'Inter', sans-serif;

    .section {
        background-color: white;
        border: 1px solid #e5e7eb;
        border-radius: 0.5rem;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    .search-input {
        .p-input-icon-left > .p-inputtext {
            padding-left: 2.5rem !important;
          }
        padding-left: 2.5rem !important;
    }

    .p-multiselect {
        width: 100%;
        height: 2.75rem;
        border-radius: 0.5rem;
    }

    .p-multiselect .p-multiselect-label {
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        color: #4b5563;
    }

    .p-multiselect .p-multiselect-label-empty {
        visibility: inherit;
      }

    .p-multiselect .p-overlay {
        width: 100%;
      }

    .p-multiselect:not(.p-disabled):hover {
        border: 1px solid #2563eb;
      }

    .p-multiselect:not(.p-disabled).p-focus {
        border: 1px solid #2563eb;
        box-shadow: 0px 0px 0px 4px rgba(147, 197, 253, 1);
      }
  
    .p-multiselect-panel {  
        width: 100%;
        max-height: 400px;
        margin-top: 0.5rem;
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
        border-radius: 0.5rem;
        overflow: auto;
        border: 1px solid #e5e7eb;
      } 
    .p-multiselect-panel .p-multiselect-header {
        background-color: white;
        border-bottom: 1px solid white;
      }
    .p-multiselect-panel .p-checkbox {
        display: none;
      }
      
    .p-multiselect-panel .p-multiselect-close {
        display: none;
      }
      
    .p-multiselect-panel .p-multiselect-filter-container .p-inputtext {
        height: 2.75rem;
        padding-left: 36px;
      }
      
    .p-multiselect-panel .p-multiselect-filter-container .p-inputtext::placeholder {
        font-size: 1rem;
        color: #9ca3af;
        font-weight: 400;
      }
      
    .p-multiselect-panel .p-multiselect-filter-icon {
        color: #4b5563;
        left: 12px;
      }
      
    .p-multiselect-panel .p-inputtext:enabled:hover,
    .p-multiselect-panel .p-inputtext:enabled:focus {
        border: 1px solid #2563eb;
        box-shadow: 0px 0px 0px 4px rgba(147, 197, 253, 1);
      }
      
    .p-multiselect-items {
        padding-top: 0;
        overflow: auto;
      }
      
    .p-multiselect-items .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border: 1px solid #2563eb;
        box-shadow: none;
      }
      
    .p-multiselect-items .p-multiselect-item {
        color: #4b5563;
        font-weight: 500;
        font-size: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      
    .p-multiselect-items .p-multiselect-item span {
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      
    .p-multiselect-items .p-multiselect-item.p-highlight {
        background-color: white;
      }
      
    .p-multiselect-items .p-multiselect-item:focus {
        box-shadow: none;
      }
      
    .p-multiselect-items .p-multiselect-empty-message {
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
        color: #4b5563;
      }
      
    .p-multiselect-items .p-checkbox .p-checkbox-box.p-highlight {
        background-color: #2563eb;
        border-color: #2563eb;
      }

    // checkbox
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: #D0D5DD;
        background-color: #F2F4F7;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #1570EF;
        background: #1570EF;
        color: #fff;
    }
    .p-checkbox .p-checkbox-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 4px !important;
    }
    .p-checkbox {
        width: 20px !important;
        height: 20px !important;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        color: #fff;
        font-weight: 600;
        font-size: 12px;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background: #1570EF;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        border-color: #1570EF;
        box-shadow: none;
    }
    .p-checkbox-box.p-disabled {
        background-color: #f2f4f7;
    }
      
    .p-dropdown {
        width: 100%;
        height: 36px;
        border-radius: 0.5rem;
      }
      
    .p-dropdown:not(.p-disabled):hover {
        border-color: #84CAFF;
      }
      
    .p-dropdown:not(.p-disabled).p-focus {
        border-color: #2563eb;
        // box-shadow: 0px 0px 0px 4px rgba(147, 197, 253, 1);
      }
      
    .p-dropdown .p-dropdown-label {
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        font-size: 1rem;
        font-family: 'Inter', sans-serif;
        color: #fff;
      }
      
    .p-dropdown-panel {
        overflow: hidden;
      }
      
    .p-dropdown-panel .p-dropdown-items {
        padding: 0;
      }
      
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
        font-size: 1rem;
        color: #4b5563;
      }
      
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
        color: #4b5563;
        background-color: #f9fafb;
      }
      
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
        color: #1d4ed8;
        background-color: #eff6ff;
      }
      
    .p-dropdown-panel::-webkit-scrollbar {
        width: 10px;
      }
      
    .p-dropdown-panel::-webkit-scrollbar-track {
        background-color: transparent;
        border: 2px solid transparent;
        border-radius: 0.5rem;
        background-clip: padding-box;
      }
      
    .p-dropdown-panel::-webkit-scrollbar-thumb {
        background-color: transparent;
        border: 2px solid transparent;
        border-radius: 0.5rem;
        background-clip: padding-box;
      }
      
    .p-dropdown-panel:hover::-webkit-scrollbar-thumb {
        background-color: #F2F4F7;
      }
      
    .p-progressbar {
        height: 0.5rem;
        background-color: #F2F4F7;
      }
      
    .p-progressbar .p-progressbar-value {
        background-color: #1570EF;
      }
      
    .p-rating .p-rating-icon {
        color: #d1d5db;
      }
      
    .p-rating .p-rating-icon.p-rating-icon-active {
        color: #FDB022;
      }
    
    .p-rating .p-rating-icon.pi-star-fill {
        color: #FDB022;
    }
      
    .t-input {
        font-size: 14px !important;
        font-size: 1rem;
        font-weight: 400 !important;
        text-overflow: ellipsis;
        border: 1px solid #d1d5db;
        border-radius: 0.5rem;
        padding: 0.75rem 1rem;
        height: 2.75rem;
        max-height: 36px;
        min-height: 36px;
      }
      
    .t-input.p-inputtext:enabled:focus {
        border: 1px solid #84CAFF;
        // box-shadow: 0px 0px 0px 4px rgba(147, 197, 253, 1);
      }
      
    .t-input.p-inputtext:enabled:hover {
        border: 1px solid #84CAFF;
      }
      
    .t-input:enabled:hover {
        border: 1px solid #2563eb;
        box-shadow: none;
      }
      
    .t-input:focus-visible {
        border: 1px solid #2563eb;
        box-shadow: none;
        outline: none !important;
      }

    .p-inputtext {
        min-height: 36px !important;
    }
    
    .filter-room {
        .p-inputtext {
            min-height: 36px !important;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            line-height: 20px;
            color: #344054;
            border: 0 none !important;
        }
        .p-inputtext:enabled:focus {
            border: 0 none !important;
            box-shadow: none !important;
        }
    }
  }

  .calendar-multi-select {
    .p-datepicker{
        padding-left: 16px;
        padding-right: 16px;
    }
    .p-datepicker table td {
        padding: 0 !important;
        font-family: 'Inter' !important;
    }

    .p-datepicker table td > span.p-highlight {
        color: #344054 !important;
        background: #F2F4F7 !important;
    }

    .p-datepicker table tbody {
        @apply space-y-1
    }

    .p-datepicker table td.p-datepicker-today > span {
        background-color: white;
        color: #344054;
    }

    .p-datepicker table td > span:focus {
        box-shadow: none;
    }

    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
        box-shadow: none;
    }

    .p-datepicker .p-datepicker-header {
        border-bottom: none;
    }

    .p-datepicker-month,
    .p-datepicker-year{
        color: #344054;
    }
    .p-datepicker-month:enabled:hover,
    .p-datepicker-year:enabled:hover
    {
        color: #1570EF !important;
    }

    .p-link:focus{
        box-shadow: none !important;
    }

    .p-datepicker-prev:focus{
        box-shadow: none !important;
    }
    
    .p-datepicker-header{
        padding: 0;
    }
    .p-monthpicker-month:not(.p-disabled):focus{
        box-shadow: none !important;
    }
    .p-yearpicker-year:not(.p-disabled):focus {
        box-shadow: none !important;
    }
    .p-yearpicker-year.p-highlight{
        color: #1570EF !important;
    }
    .p-input-icon-right>i {
        top: 25% !important
    }

    table {
        td {
            padding: unset;

            span {
                border-radius: unset;
                color: #344054;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                box-shadow: none;
                width: 2.6rem;
            }
        }

        td.p-datepicker-today {
            span {
                border-color: #1570EF;
                background-color: unset;
                border-radius: 4px;
                color: #1570EF;
            }
        }
    }

    .p-monthpicker {
        .p-monthpicker-month {
            width: 22%;
            height: 32px;
            color: #344054;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin: 8px 4px;
            box-shadow: unset !important;

            &.p-highlight {
                border: 1px solid #1570EF;
                background-color: unset;
                border-radius: 4px;
                color: #1570EF;
                box-shadow: unset !important;
            }
        }

    }

    .p-yearpicker {
        .p-yearpicker-year {
            width: 20%;
            height: 32px;
            color: #344054;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin: 8px 6px;
            box-shadow: unset !important;

            &.p-highlight {
                border: 1px solid #1570EF;
                background-color: unset;
                border-radius: 4px;
                color: #1570EF;
                box-shadow: unset !important;
            }
        }

    }
}
  