
// ---------------------------------filter--------------------------------
.search-box .p-input-icon-left {
    width: 100%;
}

.search-box .p-overlaypanel {
    margin-top: 59px;
    left: 0 !important;
    top: 0 !important;
    z-index: 1 !important;
}

.search-box .p-overlaypanel::before,
.search-box .p-overlaypanel:before {
    display: none !important
}

.p-overlaypanel:after {
    display: none;
}

// -------------------------------------*********--------------------------


// -------------------------------------*********--------------------------
// ------------------------------------- continue-search -------------------------------
.continue-search {
  .ls-history {
      .p-carousel-container{
          position: relative;
          overflow: hidden;

          .p-carousel-next {
              position: absolute;
              z-index: 8;
              right: 0.5%;
              border: 1px solid #EAECF0;
              margin: 0 8px 0 0;
              background-color: white;
          }

          .p-carousel-prev {
              position: absolute;
              z-index: 8;
              left: 0.5%;
              margin: 0 0 0 8px;
              border: 1px solid #EAECF0;
              background-color: white;
          }

          .p-carousel-prev:hover, .p-carousel-next:hover {
              background-color: rgba(16, 24, 40, 0.3);
              color: white;
          }
      }
  }

  .hide-prev .p-carousel .p-carousel-content {
    .p-carousel-prev{
        display: none;
    }
    .p-carousel-next{
        display: flex;
    }
  }
  .hide-next .p-carousel .p-carousel-content {
    .p-carousel-next{
        display: none;
    }
    .p-carousel-prev{
        display: flex;
    }
  }
  .hide-all .p-carousel .p-carousel-content {
    .p-carousel-next, .p-carousel-prev {
        display: none;
    }
  }
  .history-search-item{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    &:hover {
      box-shadow: 1px 1px 10px 0 rgba(0,0,0,.1);
      transform: scale(0.95);
    }
  }
  .replace-img{
    width: 40px !important;
    height: 40px !important;
  }
}

@media screen and (max-width: 8000px) {
    .continue-search p-carousel .p-carousel-container .p-carousel-item {
        flex: 1 0 32.15% !important;
    }
    .continue-search p-carousel .p-carousel-container .p-carousel-item-active {
        margin-right: 1.8%;
    }
    .ls-history .p-carousel-container {
        .p-carousel-next,
        .p-carousel-prev {
            height: 40px;
            width: 40px;
        }
    }
}

@media screen and (max-width: 1600px) {
    .continue-search p-carousel .p-carousel-container .p-carousel-item {
        flex: 1 0 32.2% !important;
        //max-width: 464px;
    }
    .continue-search p-carousel .p-carousel-container .p-carousel-item-active {
        margin-right: 1.6%;
    }
    .ls-history .p-carousel-container {
        .p-carousel-next,
        .p-carousel-prev {
            height: 40px;
            width: 40px;
        }
    }
}

@media screen and (max-width: 1440px) {
    .continue-search p-carousel .p-carousel-container .p-carousel-item {
        flex: 1 0 48% !important;
    }
    .continue-search p-carousel .p-carousel-container .p-carousel-item-active {
        margin-right: 4%;
    }
    .ls-history .p-carousel-container {
        .p-carousel-next,
        .p-carousel-prev {
            height: 28px;
            width: 28px;
        }
        .p-carousel-next .pi-chevron-right:before,
        .p-carousel-prev .pi-chevron-left:before {
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media screen and (max-width: 767px) {
    .continue-search p-carousel .p-carousel-container .p-carousel-item {
        flex: 1 0 100% !important;
    }
    .continue-search p-carousel .p-carousel-container .p-carousel-item-active {
        margin-right: 4%;
    }
}


// -------------------- TRENDING SECTION ------------------------
.trending-section {

    .tab_wrapper {
        border-bottom: 1px solid #FFFFFF;
    }
    .highlight-tab {
        position: relative;
    }
    
    .highlight-tab::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px; 
        background-color: #FFFFFF; 
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #65a0ad;
        cursor: pointer;
    }
}

// -------------------------------------*********--------------------------
// ------------------------------------- famous-places -------------------------------
.p-tabview {
    .p-tabview-nav {
        //padding-left: 2px;
        padding-top: 8px;
        padding-bottom: 8px;
        background: transparent;
        border: none;
        height: fit-content;
        li.p-highlight {
            .p-tabview-nav-link {
                background: #fff1f3 !important;
                color: #c01048 !important;
                border-color: none;
                i.places-nav-icon {
                    filter: none;
                }
            }
        }
        li {
            .p-tabview-nav-link {
                background: transparent;
                border: none;
                border-width: 0;
                padding: 0.25rem 0.75rem 0.25rem 0.625rem !important;
                margin-right: 0.5rem;
                font-weight: 500;
                --tw-bg-opacity: 1;
                --tw-text-opacity: 1;
                color: #344054;
                border-radius: 16px;
                background-color: var(--gray-200, #EAECF0);
                border-radius: 1rem
                /* 16px */
                ;
            }
            .p-tabview-nav-link:not(.p-disabled):focus {
                // box-shadow: inset 0 0 0 0.2rem #c7d2fe;
                box-shadow: none;
            }
            .p-tabview-nav-link:hover {
                // color: #c01048 !important;
                opacity: 0.8;
            }
        }
    }
    .p-tabview-panels {
        margin-top: 4px;
        background: transparent;
        padding: 0;
    }
}

.bs-datepicker-body table.months {
    padding: 0px;
}

// -------------------------------------*********--------------------------
//------------------------ Feature Hotel--------------------------
.hot-deal-section .grid,
.featured-hotel-section .grid {
    margin: 0px;
    margin-right: 0px !important;
}

//  ------------------------*************------------------------------------
// ------------------------------ filter-search-result -------------------------------
.section-filter-search-result {
    .view-map-btn {
        display: flex;
        padding: 4px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 6px;
        border: 1px solid #1570EF;
        background: #1570EF;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
    .filter-btn {
        border: 2px solid #EAECF0;
        background-color: #fff;
    }
    .background-result-filter {
        border: 1px solid #EAECF0;
        background: #FFF;
    }
    .title-filter {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px
    }
    span.p-slider-range.ng-star-inserted {
        width: 254px;
    }
    .slider-view-map {
        .p-slider.p-slider-horizontal {
            width: 230px !important;
        }
    }
    .p-slider .p-slider-range {
        background: #2E90FA;
        box-shadow: none;
        width: 254px;
        height: 8px;
        border-radius: 6px;
    }   
    .p-slider .p-slider-handle {
        border: 1.5px solid #2E90FA;
        box-shadow: none;
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }
    .p-slider.p-slider-horizontal .p-slider-handle {
        margin-top: -12px;
    }
    .p-slider.p-slider-horizontal {
        height: 8px;
        width: auto;
        border-radius: 6px;
    }
    .p-sidebar {
        width: 342px;
    }
    .p-slider .p-slider-handle:hover {
        background-color: #2E90FA;
        box-shadow: none;
    }
    span.p-checkbox-icon.pi.pi-check {
        width: 14px !important;
        height: 14px !important;
    }
    .p-slider:not(.p-disabled) .p-slider-handle:hover {
        background: #2E90FA;
        border: #2E90FA;
        box-shadow: none;
    }
    .p-sidebar .p-sidebar-content {
        padding: 16px !important;
    }
    // checkbox
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: #2E90FA;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #2E90FA;
        background: #EFF8FF;
    }
    .p-checkbox .p-checkbox-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .p-checkbox {
        width: 20px !important;
        height: 20px !important;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        color: #1570EF;
        font-weight: 600;
        font-size: 14px;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background: #EFF8FF;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        border-color: #2E90FA;
        box-shadow: none;
    }
    .itemFilter {
        color: #344054;
        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
    }
    .p-inputtext {
        height: 44px;
        min-height: unset;

        &:hover {
            border-color: #84CAFF;
        }
    }
}

// --------------------------------------Sort hotel searching result---------------------------------
.hotel-sort .p-dropdown {
    width: 100%
}

.sort-by {
    .p-dropdown {
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        .p-dropdown-label {
            left: 0;
            top: 0;
            padding: 10px 0 10px 16px;
        }
        .p-inputtext {
            padding: 10px 16px;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            color: #344054;
        }
        .p-dropdown-items {
            width: 240px;
            position: absolute;
            right: 0;
            top: 5px;
            background-color: white;
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid #EAECF0;
            box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        }
        span .show-icon {
            display: none;
        }
        .p-highlight {
            background-color: #F9FAFB !important;
            color: #344054 !important;
            font-weight: 600;
            border-radius: 6px;
            span .show-icon {
                display: inline;
            }
        }
        .p-dropdown-item {
            height: unset !important;
            margin: 2px 6px;
        }
        .p-dropdown-item:hover {
            background-color: #F9FAFB !important;
            border-radius: 6px;
        }
        .p-dropdown-trigger {
            width: unset !important;
            padding: 10px 16px 10px 0;
        }
        .pi-chevron-down {
            width: 20px;
            height: 20px;
        }
        .pi-chevron-down::before {
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 20px;
        }
        .p-overlay {
            z-index: 6 !important;
        }
    }
}

// ----------------------------------********---------------------------
// ---------------------------------- Map View---------------------------
.leaflet-bottom.leaflet-right {
    display: none
}

.leaflet-left .leaflet-control-zoom {
    position: relative;
}

.leaflet-control-container {
    position: absolute;
    right: 0px !important;
    bottom: 0px !important;
}

.leaflet-left .leaflet-control-zoom {
    position: absolute;
    right: 24px !important;
    bottom: 24px !important;
}

.leaflet-top .leaflet-control {
    margin-top: 0px !important;
    margin-left: 0px !important;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    border: none !important;
    // position: relative;
}

.leaflet-touch .leaflet-bar a:first-child,
.leaflet-touch .leaflet-bar a:last-child {
    color: #344054 !important;
    border-radius: 8px !important;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leaflet-touch .leaflet-bar a:last-child {
    margin-top: 8px;
}

.leaflet-touch .leaflet-bar .leaflet-control-zoom-out span,
.leaflet-touch .leaflet-bar .leaflet-control-zoom-in span {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

// ----------------------------------********---------------------------
// ---------------------------------- Count Result ---------------------------
.count-result-section {
    border: 1px solid #EAECF0;
    background-color: #fff;
    box-shadow: 0px 1px 2px 0px #1018280D;
    ;
    .btn-filter {
        border: 1px solid #D0D5DD;
        background-color: #fff;
        padding: 10px, 16px, 10px, 16px !important;
    }
}

// ------------------------------------- Hotel page room type ----------------------------------
.hotel-page-room-type {
    .divider {
        width: 100%;
        height: 1px;
        background-color: #EAECF0;
    }
    .btn-view-detail {
        height: 44px;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        background-color: #fff;
        align-items: center;
    }
    .btn-buy-number {
        width: 119px;
        height: 44px;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        padding: 10px 18px;
        background-color: #fff;
        align-items: center;
    }
}

.hotel-page-room-type-selected {
    background: linear-gradient(112deg, #FFF 60.60%, #fff2ee 100%);
    border-color: #FF4405;
}

// --------------------------------------Sort hotel searching result---------------------------------
.available-rooms-list {
    background-color: #fff;
    border: 1px solid #EAECF0;
    border-radius: 12px;
}

// -------------------------------------*********--------------------------
// ------------------------------------- hotel-amenities -------------------------------
.hotel-amenities {
    background-color: #fff;
    border: 1px solid #EAECF0;
    box-shadow: 0px 1px 2px 0px #1018280D;
}

//--------------------------------------customer review--------------------
.customer-review {
    .field-checkbox {
        margin-bottom: 0 !important;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: #2E90FA;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #2E90FA;
        background: #EFF8FF;
    }
    .p-checkbox .p-checkbox-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .p-checkbox {
        width: 20px !important;
        height: 20px !important;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        color: #1570EF;
        font-weight: 600;
        font-size: 14px;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background: #EFF8FF;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        border-color: #2E90FA;
        box-shadow: none;
    }
    .p-progressbar-label {
        display: none !important
    }
    .p-progressbar .p-progressbar-value {
        background-color: #1570EF !important;
        border-radius: 8px;
    }
    // .p-progressbar, .ng-star-inserted {
    // background-color: #EAECF0 !important;
    // }
}

// -------------------------------------*********--------------------------
// ------------------------------------- custom image modal -------------------------------
.dialog-show-imag {
    .p-dialog .p-dialog-content {
        background: none !important;
    }
    
    .p-dialog .p-dialog-content:last-of-type {
        border-bottom-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }

    .show-image-modal .p-galleria-thumbnail-container {
        background: none;
        padding: 0px;
        max-height: 100% !important;
    }

}
.p-dialog .p-dialog-content {
    padding: 0px !important;
}
.p-dialog {
    max-height: 100% !important;
}

.room-details-modal {
    .p-dialog .p-dialog-content {
        padding: 0px !important;
        border-radius: 6px !important;
    }
}

// -------------------------------------*********--------------------------
// ------------------------------------- Post modal -------------------------------
.custom-dropdown-post .p-dropdown {
    border: none;
    box-shadow: none;
}

.custom-dropdown-post .p-dropdown-trigger {
    height: 40px !important;
    justify-content: flex-start;
    display: flex;
    align-items: center;
}

.custom-dropdown-post .p-inputtext {
    max-height: 40px !important;
    min-height: 40px !important;
    padding: 0px !important;
    display: flex;
    align-items: center;
}

.custom-dropdown-post .p-dropdown {
    height: 40px !important;
}

.custom-dropdown-post .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0px;
}

.custom-dropdown-post .p-dropdown .p-dropdown-trigger {
    width: 2em !important;
    display: flex;
    align-items: center;
}

.custom-dialog {
    .p-dialog {
        border: none;
    }
    .p-dialog-header {
        border-bottom: none;
        background: linear-gradient(to top, #eff8ff 25.5%, #b1d5f3 100%);
        padding: 24px 24px 20px;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
    }
    .p-dialog-footer {
        border-top: none;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
    }
    .p-dialog-content {
        background: linear-gradient(to bottom, #eff8ff 10%, white 100%) !important;
        padding: 24px 24px 0;
    }
}

.background-opacity {
    border-radius: var(--border-radius-none, 0px);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #F9FAFB 100%);
}

// -------------------------------------*********--------------------------

// ---------------------------------- booking-details -------------------------------
.booking-detail {
    box-shadow: 0px 1px 2px 0px #1018280D;
}

.booking-details-checkout-section {
    box-shadow: 0px 1px 2px 0px #1018280D;
}

// -------------------------------------View more image--------------------------
// -------------------------------------*********--------------------------
.albums-hotel .p-tabview-panels {
    height: calc(100vh - 170px);
    overflow-y: auto;
    padding-top: 10px;
}

.albums-hotel .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #175CD3 !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 20px;
    background: none !important
}

// -------------------------------------Available room list--------------------------
// -------------------------------------*********--------------------------
.available-rooms-list-section {
    .p-progress-spinner-svg {
        width: 80%;
        height: 80%;
    }
}

// -------------------------------------*********--------------------------

//-----------------------------------------Map------------------------------------
.custom-marker-icon {
    display: flex;
    position: relative;
    background: #FFFFFF;
    height: 36px;
    width: max-content;
    padding: 4px 12px;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    border-radius: 8px;
    border: 2px solid #FF5316;
    .currency {
        color: #344054;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
    .marker-text {
        color: #344054;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
}

.custom-marker-icon::before {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 8px solid #FF5316;
}

.marker-text::before {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 7px solid #FFFFFF
}

.custom-marker-icon:hover {
    background-color: #FFD6AE;
    .marker-text::before {
        content: "";
        position: absolute;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 7px solid #FFD6AE
    }
}

.customBox {
    position: absolute;
    transform: translate(-50%, -100%);
    z-index: 10001;
    height: 252px;
    display: flex;
    width: 452px;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid #EAECF0;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin: 0;
}

.ranking-point {
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    max-width: 49px;
    max-height: 32px;
    border-radius: 6px;
    border: 1px solid #1570EF;
}

.text-ranking-point {
    color: #1570EF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.text-feel {
    color: #344054;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.text-number-views {
    color: #667085;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.title-name-hotel {
    color: #101828;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.text-from {
    color: #344054;
    text-align: right;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.btn-view-available {
    border-radius: 8px;
    border: 1px solid #1570EF;
    background: #1570EF;
    color: #FFF;
    padding: 9px 18px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.selected .custom-marker-icon {
    background-color: #FF6327;
    .marker-text::before {
        content: "";
        border-top: 8px solid #FF6327
    }
}

.img-marker {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    border: 1px solid #EAECF0;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}


// -------------------------------------Proerty amenities--------------------------
.property-amenities-ota-section,
.room-amenities-section {
    // title
    .p-dialog-title {
        font-family: 'Inter', 'sans-serif';
        font-weight: 600 !important;
        font-size: 18px;
        line-height: 28px;
    }

    //close icon
    .p-dialog-header-close-icon.pi {
        font-size: 20px;
    }

    // checkbox
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: #D0D5DD;
        background-color: #F2F4F7;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #1570EF;
        background: #1570EF;
        color: #fff;
    }
    .p-checkbox .p-checkbox-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 4px !important;
    }
    .p-checkbox {
        width: 20px !important;
        height: 20px !important;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        color: #fff;
        font-weight: 600;
        font-size: 12px;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background: #1570EF;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        border-color: #1570EF;
        box-shadow: none;
    }
    .itemFilter {
        color: #344054;
        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
    }
    // Radio button
    .p-radiobutton .p-radiobutton-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        &:hover {
            border-color: #1570EF !important;
        }
    }
    .p-radiobutton .p-radiobutton-box.p-highlight {
        background: #EFF8FF;
        border-color: #1570EF;
        &:hover {
            background: #EFF8FF;
        }
    }
    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background-color: #1570EF;
        width: 8px;
        height: 8px;
    }
    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: #1570EF;
        background: #EFF8FF;
    }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }
}

.property-amenities-ota-section-view-only {
    .top-amenities-checkbox {
        // checkbox
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: #D0D5DD;
            background-color: #F2F4F7;
        }
        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #1570EF;
            background: #1570EF;
            color: #fff;
        }
        .p-checkbox .p-checkbox-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 4px !important;
        }
        .p-checkbox {
            width: 20px !important;
            height: 20px !important;
        }
        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
            background: #1570EF;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
            border-color: #1570EF;
            box-shadow: none;
        }
    }
    // checkbox
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: #D0D5DD;
        background-color: #F2F4F7;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #B2DDFF;
        background: #B2DDFF;
        color: #fff;
    }
    .p-checkbox .p-checkbox-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 4px !important;
    }
    .p-checkbox {
        width: 20px !important;
        height: 20px !important;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        color: #fff;
        font-weight: 600;
        font-size: 12px;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background: #B2DDFF;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        border-color: #B2DDFF;
        box-shadow: none;
    }
    .itemFilter {
        color: #344054;
        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
    }
    // Radio button
    .p-radiobutton .p-radiobutton-box {
        width: 20px;
        height: 20px;
        border: 1px solid #F2F4F7;
        &:hover {
            border-color: #F2F4F7 !important;
        }
    }
    .p-radiobutton .p-radiobutton-box.p-highlight {
        background: #fff;
        border-color: #B2DDFF;
        &:hover {
            background: #B2DDFF;
            border-color: #B2DDFF !important;
        }
    }
    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background-color: #B2DDFF;
        width: 8px;
        height: 8px;
    }
    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: #B2DDFF;
        background: #fff;
    }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }
}


// ------------------------------------- Select destination modal -------------------------------
.custom-dialog-search-bar {
    .p-dialog {
        border: none;
    }
    .p-dialog-header {
        display: none;
    }
    .p-dialog-content {
        background: rgba(0, 0, 0, 0.15);
        padding: 24px 24px 0;
        display: flex;
        position: relative;
        justify-content: center;
        margin-right: 10px;
    }
    .p-dialog-content .box-search-bar {
        background: linear-gradient(to bottom, #d8ecfc 10%, white 100%) !important;
        position: absolute;
        top: 30%;
        display: flex;
        justify-content: center;
    }
  }
  
  .background-opacity {
    border-radius: var(--border-radius-none, 0px);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #F9FAFB 100%);
  }
  .container-search-available #container-search
{
    box-shadow: 0px 3px 8px 3px #EAECF0 !important;
}
  // -------------------------------------*********--------------------------
  // ---------------------------------- price-details -------------------------------
  .price-detail {
    .p-dialog {
        max-height: 90% !important;
    }
    box-shadow: 0px 1px 2px 0px #1018280D;
    .p-dialog .p-dialog-header {
        border-bottom: 1px solid #EAECF0;
        height: 73px;
    }
    .p-dialog .p-dialog-content {
        padding: 0px !important;
        border-radius: 0px !important;
    }
    .p-dialog .p-dialog-header .p-dialog-title {
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        font-family: "Inter";
        color: #101828;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon {
        color: #667085;
    }
    .p-inputswitch {
        width: 100%;
        height: 100%;
    }
    .p-inputswitch.p-focus .p-inputswitch-slider {
        box-shadow: none;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch .p-inputswitch-slider:before {
        width: 16px;
        height: 16px;
        left: 0.15rem;
        margin-top: -8px;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        transform: translateX(1rem);
    }
    .soctrip-coins {
        border: 1px solid #D0D5DD;
        background: #FCFCFD;
    }
    .left-card-voucher {
        border: 1px solid #84CAFF;
        background: #EFF8FF;
        border-right: 1px dashed #84CAFF;
    }
    .p-dialog-header-icons {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 24px !important;
        height: 24px !important;
    }
    .pi {
        font-size: 12px;
        color: #667085;
        font-weight: 700;
    }
    .p-dialog-header-close-icon {
        width: 12px !important;
        height: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p-dialog .p-dialog-content {
        background: #fff !important;
        height: auto;
        padding-top: 12px !important;
        padding-bottom: 30px !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .p-component-overlay {
        background-color: rgba(0, 0, 0, 0.4) !important;
    }
    .left-card-voucher-active {
        border: 1px solid #1570EF;
        background: #EFF8FF;
        border-right: 1px dashed #1570EF;
    }
    .left-card-voucher-inactive {
        border: 1px solid #D0D5DD;
        background: #F2F4F7;
        border-right: 1px dashed #D0D5DD;
    }
    .left-card-voucher-by-code {
        border: 1px solid #ff9c66;
        background: #FFE6D5;
        border-right: 1px dashed #ff9c66;
    }
    .left-card-voucher-by-code-active {
        border: 1px solid #FF4405;
        background: #FFE6D5;
        border-right: 1px dashed #FF4405;
    }
    .right-card-voucher {
        border: 1px solid #84CAFF;
        background: #FFF;
        border-left: none;
    }
    .right-card-voucher-avtive {
        border: 1px solid #1570EF;
        background: #FFF;
        border-left: none;
    }
    .right-card-voucher-inactive {
        border: 1px solid #D0D5DD;
        background: #FFF;
        border-left: none;
    }
    .right-card-voucher-by-code-active {
        border: 1px solid #FF4405;
        background: #FFF;
        border-left: none;
    }
    .right-card-voucher-by-code {
        border: 1px solid #ff9c66;
        background: #FFF;
        border-left: none;
    }
    .confirm-voucher-btn {
        border: 1px solid #1570EF;
        background: #1570EF;
    }
    .cancel-voucher-btn {
        border: 1px solid #D0D5DD;
        background: #fff;
    }
    .promo-code-card {
        background: #F9FAFB;
        border: 1px solid #EAECF0;
        .input-promo-code {
            border: 1px solid;
            background: #fff;
        }
    }
    .text-total-price {
        background: linear-gradient(263.89deg, #FF692E, #FF4405);
        -webkit-background-clip: text;
        color: transparent;
    }
  }
  
  // ------------------------------------**********---------------------------
  
  // ------------------------------------My booking details-----------------------------
  .my-booking-details-section {
    .p-dialog {
        background: #fff !important;
        border-color: #EAECF0 !important;
        border-radius: 12px !important;
        max-height: 800px !important;
    }
    .p-dialog .p-dialog-content {
        padding: 0px 24px 0px 24px !important;
    }
    .p-dialog .p-dialog-footer {
        border-radius: 12px !important;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 24px !important;
        height: 24px !important;
    }
    .pi {
        font-size: 12px;
        color: #667085;
        font-weight: 700;
    }
    .p-dialog-header-close-icon {
        width: 12px !important;
        height: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p-dialog-header {
        display: flex !important;
        align-items: flex-start !important;
        justify-content: space-between !important;
        flex-shrink: 0 !important;
        border-radius: 12px !important;
    }
  }
  
  // ------------------------------------**********---------------------------
  // ------------------------------------Contact-info-----------------------------
  .contact-info {
    .p-inputtext {
        padding: 10px 14px !important;
        border-radius: 8px !important;
    }
    .p-inputswitch {
        width: 36px;
        height: 20px;
    }
    .p-inputswitch.p-focus .p-inputswitch-slider {
        box-shadow: none;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch .p-inputswitch-slider:before {
        width: 16px;
        height: 16px;
        margin-top: -8px;
        transform: translateX(-1px);
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        transform: translateX(0.9rem);
    }
    .iti--separate-dial-code .iti__selected-flag {
        background-color: transparent !important;
        border-radius: 8px 0 0 8px;
        outline: none;
    }
    .iti.iti--allow-dropdown.iti--separate-dial-code.iti--show-flags {
        width: 100%;
    }
    .iti__country-list--dropup {
        margin-bottom: 6px !important;
    }
    .iti__country-list {
        margin-top: 6px !important;
    }
    .p-inputtext:enabled:hover {
        border-color: #ced4da !important;
    }
    .p-inputtext.ng-invalid.ng-touched {
        border-color: #e24c4c;
    }
    .tel-input.ng-invalid.ng-touched {
        border-color: #e24c4c;
    }
    .iti__arrow {
        margin: 0 12px 28px 4px;
        border-left: none;
        border-right: none;
        border-top: none;
    }
    .iti__selected-flag .iti__flag {
        margin-left: 3px;
        margin-right: 4px;
        scale: 1.35;
        border-radius: 4px;
    }
    .iti__flag {
        border-radius: 4px;
    }
    .iti__arrow--up {
        border-bottom: none;
        border-top: none;
    }
  }
  
  // -------------------------------------*********--------------------------
  // ---------------------------------- search booking id modal -------------------------------
  .search-booking-id-modal-section {
    .p-dialog .p-dialog-content {
        border-radius: 0px !important;
    }

    .p-component-overlay {
        background-color: rgba(0, 0, 0.2, 0.6) !important;
    }
    .p-dialog .p-dialog-header {
        border-top-left-radius: 12px !important;
        border-top-right-radius: 12px !important;
    }
    .p-dialog .p-dialog-content {
        background: #fff !important;
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
        padding-left: 32px !important;
        padding-right: 32px !important;
    }
    .p-dialog-header {
        justify-content: center !important;
        position: relative;
    }
    .p-dialog .p-dialog-header-icons {
        position: absolute;
        right: 20px;
    }
    .p-dialog .p-dialog-header .p-dialog-title {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        font-family: "Inter";
        color: #101828;
    }
    .p-dialog-header-icons {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 24px !important;
        height: 24px !important;
    }
    .pi {
        font-size: 12px;
        color: #667085;
        font-weight: 700;
    }
    .p-dialog-header-close-icon {
        width: 12px !important;
        height: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .iti--separate-dial-code .iti__selected-flag {
        background-color: #fff !important;
        border-radius: 8px 0 0 8px;
        outline: none;
    }
    .iti.iti--allow-dropdown.iti--separate-dial-code.iti--show-flags {
        width: 100%;
    }
    .iti__country-list--dropup {
        margin-bottom: 6px !important;
    }
    .iti__country-list {
        margin-top: 6px !important;
    }
    .p-inputtext:enabled:hover {
        border-color: #ced4da !important;
    }
    .p-inputtext.ng-invalid.ng-touched {
        border-color: #e24c4c;
    }
    .tel-input.ng-invalid.ng-touched {
        border-color: #e24c4c;
    }
  }
  
  .albums-hotel .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: none !important;
    color: #667085 !important;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  
  .albums-hotel .p-tabview-nav li {
    height: 31px;
  }
  
  .albums-hotel .p-tabview-nav li:not(.p-highlight):not(.p-tabview-ink-bar) {
    border-bottom: solid 1px;
    @apply border-palette-gray-200
  }
  
  .albums-hotel .p-highlight.ng-star-inserted {
    border-bottom: 2px solid #175CD3;
  }
  
  .albums-hotel .p-tabview .p-tabview-nav {
    padding-top: 16px !important;
  }
  
  .albums-hotel .grid {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  
  .albums-hotel .p-tabview .p-tabview-panels {
    margin-top: 16px !important;
  }
  
  .albums-hotel .p-tabview .p-tabview-panels {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

// ------------------------------------ slideshow--------------------------
.box-slideshow .p-carousel-content {
    position: relative;
}

.box-slideshow .p-carousel-content ul.p-carousel-indicators {
    position: absolute;
    bottom: 0;
    width: 100%;
    align-items: center;
    padding: 2px !important;
}

.box-slideshow .p-carousel-content ul.p-carousel-indicators li.p-carousel-indicator button {
    height: 4px !important;
    width: 16px;
    background-color: white;
    border-radius: 2px !important;
    padding: 0 5px 0 5px;
}

.box-slideshow .p-carousel-content ul.p-carousel-indicators li.p-carousel-indicator.p-highlight button {
    background-color: #0166ff !important;
    width: 25px !important;
}

.box-slideshow .p-carousel-container {
    position: relative;
}

.box-slideshow .p-carousel .p-carousel-content .p-carousel-prev {
    position: absolute;
    z-index: 10;
    // top: 0;
    left: 12px;
    height: 40px;
    width: 40px;
    color: white;
    background: rgba(16, 24, 40, 0.4);
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 0;
    // gap: 8px;
}

.box-slideshow .p-carousel .p-carousel-content .p-carousel-next {
    position: absolute;
    right: 12px;
    // top: 0;
    z-index: 10;
    height: 40px;
    width: 40px;
    color: white;
    background: rgba(16, 24, 40, 0.4);
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 8px;
    margin: 0;
}

.box-slideshow .p-carousel .p-carousel-content .p-carousel-next:focus {
    box-shadow: none;
}

.box-slideshow .p-carousel .p-carousel-content .p-carousel-prev:focus {
    box-shadow: none;
}

.box-slideshow .p-carousel .p-carousel-content {
    .p-carousel-next,
    .p-carousel-prev {
        padding: 0;
        text-align: center;
    }
    .p-carousel-next .pi-chevron-right,
    .p-carousel-prev .pi-chevron-left {
        width: 20px;
        height: 20px;
        line-height: 20px;
    }
}

.tourist-spot .hide .p-carousel-content .p-carousel-prev {
    display: none;
}

.tourist-spot .show .p-carousel-content .p-carousel-next,
.tourist-spot .show .p-carousel-content .p-carousel-prev,
.tourist-spot .hide .p-carousel-content .p-carousel-next {
    color: gray;
    background: white;
    height: 40px;
    width: 40px;
    //top: 29%;
    //top:50%;
    transform: translateY(-50%);
}

.tourist-spot .p-carousel .p-carousel-content {
    .p-carousel-next,
    .p-carousel-prev {
        padding: 0;
        text-align: center;
    }
    .p-carousel-next .pi-chevron-right,
    .p-carousel-prev .pi-chevron-left {
        width: 20px;
        height: 20px;
        line-height: 20px;
    }
}


//------------------------------------ DateRangePicker--------------------------
.theme-green .bs-datepicker-head {
    background-color: #FFFFFF;
}

bs-datepicker-container,
bs-daterangepicker-container {
    z-index: 1200 !important;
}

bs-month-calendar-view {
    padding: 16px;
}

bs-years-calendar-view {
    padding: 16px;
}

bs-days-calendar-view:first-of-type {
    border-right: 1px solid #EAECF0;
}

.bs-datepicker-body table.months {
    padding: 0px;
}

.bs-datepicker-body table th {
    color: var(--gray-700, #344054);
    text-align: center;
    font-size: 14px;
    font-family: Inter;
    line-height: 20px;
    font-style: normal;
    font-weight: 500;
    padding: 10px 5px;
}

.bs-datepicker-head button:hover {
    padding: 0px 10px 0px 10px !important;
}

.search-map {
    .bs-datepicker {
        left: 52px;
    }
}

.bs-datepicker {
    border-radius: 8px;
    bottom: 8px;
}

.bs-datepicker-body table.days td.active:not(.select-start):before,
.bs-datepicker-body table.days td.in-range:not(.select-start):before,
.bs-datepicker-body table.days span.active:not(.select-start):before,
.bs-datepicker-body table.days span.in-range:not(.select-start):before {
    background: #F2F4F7;
}

.bs-datepicker-head button {
    color: black;
}

.bs-datepicker-body table td span.selected {
    background-color: #1570EF;
    color: #FFFFFF !important;
}

.bs-datepicker-body table td {
    padding: 1px;
}

.bs-datepicker-body table td span {
    height: 35px;
    width: 100px;
}

.bs-datepicker-body table.days span.is-highlighted.in-range:before {
    right: 10px;
    left: -5px;
}

.bs-datepicker-body table.days span.in-range.select-end:before {
    content: "";
    left: -10px;
    top: 6px;
    bottom: 6px;
    right: 21px;
}

bs-datepicker-body table td:before,
.bs-datepicker-body table td span:before {
    content: "";
    bottom: 6px;
    left: -8px;
    right: -5px;
    top: 6px;
}

.bs-datepicker-container {
    padding: 0px;
}

.bs-datepicker-head {
    min-width: auto;
    height: 25px;
    padding: 0px;
}

bs-days-calendar-view:first-of-type {
    border-right: 1px solid #EAECF0;
}

bs-days-calendar-view {
    width: 328px;
    padding: 24px 16px;
    height: 392px;
}

.bs-datepicker-body table td span.disabled {
    color: #9aaec1 !important;
}

.p-overlaypanel-content {
    padding: 0px !important;
}

.bs-datepicker-body {
    border: none;
    padding: 0px;
}

.bs-datepicker-body:first-child {
    border-left: 1px solid;
}

.ng-trigger.ng-trigger-animation {
    border-radius: 8px;
    transform-origin: top center !important;
}

.search-hotel button {
    position: relative;
    padding: 16px;
    top: 0px;
    left: 0px !important;
    border-radius: 12px !important;
    height: 52px;
}

.search-hotel button {
    position: relative;
    padding: 16px;
    top: 0px;
    left: 0px !important;
    border-radius: 12px !important;
    height: 52px;
    justify-content: center;
}
  
  .search-hotel  button .p-button-label {
    flex: none !important;
  }

.search-hotel button span {
    position: relative;
    bottom: 1px;
    left: 0px;
    font-weight: 500;
}

.customTodayClass {
    background-color: #F2F4F7;
    border-radius: 50%;
    border: 1px solid #1570EF;
}

.containerClassCalendar .bs-datepicker-body table td span.selected:after {
    content: "";
    left: 20px;
    right: 15px;
    background: #F2F4F7;
}

.containerClassCalendar .bs-datepicker-body table td span.selected:before {
    content: "";
    left: 25px;
    right: 15px;
    bottom: 20px;
    top: 20px;
    background: #F2F4F7;
}

.containerClassCalendar .bs-datepicker-body table td span {
    text-align: center;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
}

.bs-datepicker-body .days.weeks {
    height: 310px;
}

.p-inputtext:enabled:focus {
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 8px;
    border: 1px solid #84CAFF !important;
    background: var(--base-white, #FFF);
    box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px #84CAFF;
}

.p-input-icon-left>i:first-of-type {
    top: 23px
}

.bs-datepicker-head button.current {
    font-weight: 600;
}

.bs-datepicker-body table.days span {
    color: var(--gray-700, #344054);
    width: 40px;
    height: 40px;
    line-height: 40px;
}

.bs-datepicker-head button[disabled],
.bs-datepicker-head button[disabled]:hover {
    color: #D0D5DD;
    background-color: none !important;
}

.buttonText button {
    width: 185px !important;
    max-width: 100%;
    left: 0 !important;
    margin: -7px 0px 0px 0px;
    padding: 0 !important;
    top: 7px !important;
}

.buttonText button span {
    top: 0px !important;
}

.search-available {
    margin-top: 0 !important;
}

@media screen and (min-width: 320px) {
    .tourist-spot .p-carousel .p-carousel-content {
        .p-carousel-prev {
            left: 8%;
        }
        .p-carousel-next {
            right: 8%;
        }
    }
}

@media screen and (min-width: 375px) {
    .tourist-spot .p-carousel .p-carousel-content {
        .p-carousel-prev {
            left: 10%;
        }
        .p-carousel-next {
            right: 10%;
        }
    }
}

@media screen and (min-width: 425px) {
    .tourist-spot .p-carousel .p-carousel-content {
        .p-carousel-prev {
            left: 3%;
        }
        .p-carousel-next {
            right: 3%;
        }
    }
}

@media screen and (min-width: 768px) {
    .tourist-spot .p-carousel .p-carousel-content {
        .p-carousel-prev {
            left: 6%;
        }
        .p-carousel-next {
            right: 6%;
        }
    }
}

@media screen and (min-width: 810px) {
    .tourist-spot .p-carousel .p-carousel-content {
        .p-carousel-prev {
            left: 3%;
        }
        .p-carousel-next {
            right: 3%;
        }
    }
}

@media screen and (min-width: 1024px) {
    .tourist-spot .p-carousel .p-carousel-content {
        .p-carousel-prev {
            left: 5%;
        }
        .p-carousel-next {
            right: 5%;
        }
    }
    #addressPanel {
        width: 652px !important;
    }
}

@media screen and (min-width: 1200px) {
    .tourist-spot .p-carousel .p-carousel-content {
        .p-carousel-prev {
            left: 3%;
        }
        .p-carousel-next {
            right: 3%;
        }
    }
}

@media screen and (min-width: 1440px) {
    .tourist-spot .p-carousel .p-carousel-content {
        .p-carousel-prev {
            left: 2%;
        }
        .p-carousel-next {
            right: 2%;
        }
    }
}

@media screen and (min-width: 1680px) {
    .tourist-spot .p-carousel .p-carousel-content {
        .p-carousel-prev {
            left: 2.5%;
        }
        .p-carousel-next {
            right: 2.5%;
        }
    }
}

@media screen and (max-width: 991px) {
    #container-search {
        height: 100% !important;
        padding: 4px 8px 6px 8px !important;
        .search-hotel {
            top: -16px !important;
            left: 0px !important;
        }
    }
    #container-search .p-button {
        width: 100% !important;
        padding: 10px;
        position: relative;
    }
    .search-hotel {
        padding: 15px 0px;
        left: 0px !important;
    }
    .p-overlaypanel .p-overlaypanel-content {
        width: auto;
    }
    .bs-datepicker-body:first-child {
        border: none;
    }
    .search-box .p-overlaypanel {
        width: 100%;
    }
    .bs-daterangepicker-container {
        width: 75% !important;
    }
    .search-hotel button {
        top: 14px !important;
        left: 0px !important;
        border-radius: 12px !important;
    }
    .dropdown-age .p-dropdown {
        width: 100% !important;
    }
    .container-button {
        max-width: 100% !important;
        margin-top: 15px;
    }
    #container-search .container-place {
        top: 0px !important;
    }
    .container-select-date {
        top: 6px !important;
        margin-top: -6px !important;
    }
    #container-search #selectPeople {
        top: 2px !important;
        margin-top: 6px;
    }
    .search-hotel button span {
        left: 0;
    }
    .search-map {
        .search-hotel button {
            top: 12px !important;
        }
    }
}

@media (min-width:992px) and (max-width:1023px) {
    .containerClassCalendar {
        left: 15px;
        top: 2px;
    }
    .bs-datepicker {
        transform-origin: top center !important;
    }
    .search-hotel button span {
        left: 0;
    }
    #container-num-room {
        width: 100%;
    }
    #container-search .p-button {
        width: 100% !important;
        padding: 14px;
        position: relative;
        top: 13px;
        height: 50px !important;
    }
    .search-hotel {
        padding: 16px 0px;
        border-radius: 12px;
        left: 0px !important;
        top: -13px;
    }
    .search-hotel button {
        top: 11px;
        border-radius: 8px !important;
    }
    .p-overlaypanel .p-overlaypanel-content {
        width: auto !important;
    }
    .ng-trigger.ng-trigger-animation {
        // width: 100%;
    }
    .search-hotel button {
        padding: 12px;
        left: 0px !important;
    }
    .dropdown-age .p-dropdown {
        width: 100% !important;
    }
    .container-button {
        max-width: 100% !important;
    }
    .buttonText button {
        top: 15px !important;
    }
    .search-map .search-hotel {
        top: -14px !important;
    }
    #container-search {
        height: 60px !important;
        gap: 2px !important;
        #selectPeople {
            top: 8px !important;
            width: 180px !important;
            max-width: fit-content !important;
        }
        .container-place {
            top: 7px;
            width: max-content !important;
        }
        .search-hotel {
            top: -16px;
            display: math;
            left: 1px !important;
        }
    }
    .search-box .p-input-icon-left {
        width: 100%;
        top: 1px;
        left: -3px
    }
    .container-select-date {
        top: 12px !important;
    }
    #addressPanel {
        width: 500px !important;
    }
    .search-box .p-overlaypanel {
        width: auto !important;
    }
    #container-num-room {
        width: 332px !important;
    }
    .search-map {
        .p-button {
            top: 11px !important;
        }
    }
    .search-available {
        .search-hotel {
            top: -8px !important;
        }
        .buttonText button {
            padding: 15px 6px 15px 6px !important;
            top: 14px !important;
        }
        .buttonText button span {
            top: 0px !important;
        }
        .search-hotel button {
            border-radius: 12px !important;
        }
    }
    .bs-datepicker {
        left: 160px;
    }
}

@media (min-width:1024px) and (max-width: 1279px) {
    .container-place {
        top: 9px !important;
        width: 330px !important;
    }
    .container-select-date {
        top: 12px !important;
        margin: 0px 5px;
    }
    .container-select-people {
        top: 8px !important;
        width: 330px !important;
    }
    .container-button {
        top: 8px !important;
    }
    .view-detail-search-bar,
    .search-view-content-search-bar {
        .search-hotel {
            display: inline-table;
            top: -4px;
            left: -3px !important;
        }
    }
    .search-hotel {
        display: inline-table;
        top: -11px;
        left: -3px !important;
    }
    .buttonText {
        height: 52px !important;
        top: -12px !important;
    }
    .search-hotel button {
        padding: 15px !important;
        left: 1px !important;
    }
    .search-hotel button span {
        left: 0;
    }
    .search-available {
        .search-hotel button {
            padding: 10px !important;
            left: 0 !important;
        }
        .buttonText button span {
            top: 0px !important;
        }
    }
    .bs-datepicker {
        left: 145px;
    }
}

.search-address-list::-webkit-scrollbar {
    width: 4px !important;
    border-radius: 8px;
}

.icon-maker-pin {
    border-radius: 20px;
    background: #D1E9FF;
}

//---------------------------------------dropdown Age ---------------------
.p-dropdown .p-dropdown-label.p-placeholder {
    color: #475467;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0px 32px 0px 0px;
    position: relative;
}

.dropdown-age .p-dropdown span::before {
    color: #475467;
    font-size: 14px;
    font-style: normal;
    font-family: primeicons;
    font-weight: 500;
    line-height: 20px;
}

//'primeicons'
.p-dropdown .p-dropdown-trigger {
    width: 1.25rem !important;
    margin-right: 10px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    height: 44px !important;
}

.p-dropdown-panel .p-dropdown-items {
    padding: 0px !important;
}

.p-dropdown-items-wrapper {
    max-height: 352px !important;
}

.dropdown-age .p-dropdown span {
    padding: 10px 0px 10px 0px;
    text-align: center;
    color: #475467;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.dropdown-age .p-dropdown {
    height: 40px !important;
    width: 137px;
    border-radius: 6px;
}

.p-dropdown:not(.p-disabled).p-focus {
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 8px;
    border: 1px solid #84CAFF !important;
    background: var(--base-white, #FFF);
    box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px #84CAFF;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: #84CAFF;
}

.agency-support {
    .grid {
        margin: 0 !important;
    }
    .p-input-icon-left > .p-inputtext {
        padding-left: 2.5rem !important;
      }
    .t-pending-item img {
        width: 40px !important;
    }

    .t-agency-support-container {
        margin-top: 12px !important;
        width: 100%;
        min-width: 886px !important;
        height: 100%;
        & > div {
          margin-top: 12px !important;
          gap: 16px !important;
  
          & > div {
            min-width: 160px !important;
            width: auto !important;
            & > div {
              padding: 16px !important;
              display: flex;
              flex-direction: column;
              gap: 8px;
            }
            
            .t-pending-list-container {
                & > div {
                  display: flex;
                  flex-direction: column;
                  gap: 8px;

                  .t-pending-item{
                    padding: 0px !important;
                    button {
                    //   max-width: 90px !important; &:hover {
                        &:hover {
                            background-color: #f2f4f7;
                          }
                    }
                  }
                }
            }

            .t-chat-item p {
                max-width: 200px !important;
            }
          }
        }
      .user-scroll {
        width: 30% !important;
        min-width: 200px !important;
      }
      
      .t-chatbox-header p {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden; 
          text-overflow: ellipsis;
          max-width: 100%;
      }
  
      .t-chatbox-header button {
          height: auto !important;
          max-height: 60px !important;
          overflow: hidden;
          line-height: 1rem !important;
          min-height: 44px !important;
      }
    }

    .t-chatbox-group-container {
        max-height: 650px !important;
    }

    .t-agency-support-container > div:first-child {
        margin-top: 24px !important;
        gap: 8px !important;
    }
}

#t-chat-popup {
    .sc-chat-button{
        bottom: 12px !important;
        right: 32px !important
    }
}

.t-chat-popup {
    .grid {
        margin: 0 !important;
    }
    .p-overlaypanel {
        padding-left: 0px !important;
    }
    .p-overlaypanel-content {
        padding: 0px !important;
        border: 0 none !important;
    }
    // .p-listbox .p-listbox-list .p-listbox-item{
    //     padding: 0px !important;
    // }
    // .p-overlay {
    //     padding: 0px !important;
    // }
    // .p-listbox {
    //     padding: 0px !important;
    //     .p-listbox-list{
    //         padding: 0px !important;
    //     }
    // }
    .p-dialog .p-dialog-content {
        // padding: 0px 16px 0px 0px !important;
        padding: 16px !important;
    }
    .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
        margin: 0px !important;
    }
    .p-dialog .p-dialog-header {
        border-bottom: 1px solid #e9ecef !important;
        background: #ffffff;
        color: #212529;
        padding: 1rem;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }
    .p-dialog .p-dialog-footer {
        border-top: 1px solid #e9ecef !important;
        background: #ffffff;
        color: #212529;
        padding: 1rem;
        text-align: right;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .p-dialog .p-dialog-footer button {
        height: 36px !important;
        padding-top: 0px !important; 
        padding-bottom: 0px !important;
    }
    .ng-trigger .ng-trigger-animation {
        // width: 100% !important;
        padding: 0 !important;
        box-shadow: none !important;
    }
    .ng-trigger .ng-trigger-overlayContentAnimation {
        padding: 0px !important;
        box-shadow: none !important;
    }
    .p-inputtext {
        // height: 39px !important;
        min-height: 39px !important;
        padding: 0.35rem .75rem !important;
    }
}
//----------------------------------Special request----------------
.special-request {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); 
    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #1570EF !important;
        background: #1570EF !important;
      }
      .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: #1570EF !important;
      }
      .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #C7D2FE !important;
        border-color: #1570EF !important;
      }
      .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        border-color: #1570EF !important;
        background: #1570EF !important;
        color: #ffffff;
      }
}

// ---------------------------------Cross sale --------------------------
.cross-sale-section {

    .p-rating .p-rating-icon {
        color: #FFFF;
        font-size: 12px;
        margin-right: 4px !important;
        margin-left: 0px !important;
      }
      
    .p-rating .p-rating-icon.p-rating-icon-active {
        color: #FAC515;
      }
    
    .p-rating .p-rating-icon.pi-star-fill {
        color: #FAC515;
    }

    .t-cross-sale-card {        
        cursor: pointer;
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        box-shadow: 4px 4px 12px -2px rgba(62, 74, 100, 0.05);
        &:hover {
            border-width: 1px !important;
            border-style: solid;
            border-color: #84caff !important;
            border-radius: 0.5rem;
            background-color: #f9fafb;
            box-shadow:
            0px 0px 0px 4px #eaf3ff,
            0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
        .t-image {
            height: 100% !important;
            border-radius: 4px !important;
        }
        .t-content {
            padding: 12px !important;
        }
        border-radius: 8px !important;
        overflow: hidden !important;
      }

    .t-soctrip-cross-sale
    {
        // margin: 0 -8px !important;
        gap: 0px !important;
       .t-body {
            display: flex;
            justify-content: center;
            gap: 12px !important;
            .t-image {
                padding: 12px;
                background-color: white !important;
            }
            .t-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .t-content {
                gap: 2px !important;
                padding: 12px !important;
                padding-left: 0px !important;
                .t-title {
                    font-size: 14px !important;
                    height: 24px;
                }
                .p-rating .p-rating-icon.p-icon {
                    width: 16px !important;
                    height: 16px !important;
                }
                .t-content-footer {
                    .t-price {
                        font-size: 1rem !important;
                    }
                    .t-btn-book {
                        font-size: 0.75rem !important;
                    }
                }
            }
        }
    }

    .p-carousel-items-container .p-carousel-item:not(:last-child) .carousel-item-container {
        margin-right: 16px;
        gap: 12px !important;
    }

    .p-carousel-item {
        display: flex;
        justify-content: center !important;
    }
    
    .p-carousel-content {
        position: relative;
    }
    
    .p-carousel-content ul.p-carousel-indicators {
        position: absolute;
        bottom: 0;
        width: 100%;
        align-items: center;
        padding: 2px !important;
    }
    
    .p-carousel-content ul.p-carousel-indicators li.p-carousel-indicator button {
        height: 4px !important;
        width: 16px;
        background-color: white;
        border-radius: 2px !important;
        padding: 0 5px 0 5px;
    }
    
    .p-carousel-content ul.p-carousel-indicators li.p-carousel-indicator.p-highlight button {
        background-color: #1570EF !important;
        width: 25px !important;
    }
    
    .p-carousel-container {
        position: relative;
    }
    
    .p-carousel .p-carousel-content .p-carousel-prev {
        position: absolute;
        z-index: 10;
        // top: 0;
        left: 12px;
        height: 40px;
        width: 40px;
        border-radius: 24px;
        color: #101828;
        border: 1px solid #D0D5DD;
        background: #FFF;
        box-shadow: 4px 4px 12px -2px rgba(62, 74, 100, 0.05);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        margin: 0;
    }
    
    .p-carousel .p-carousel-content .p-carousel-next {
        position: absolute;
        right: 12px;
        // top: 0;
        z-index: 10;
        height: 40px;
        width: 40px;
        color: #101828;
        border: 1px solid #D0D5DD;
        background: #FFF;
        box-shadow: 4px 4px 12px -2px rgba(62, 74, 100, 0.05);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 8px;
        margin: 0;
    }
    
    .p-carousel .p-carousel-content .p-carousel-next:focus {
        box-shadow: none;
    }
    
    .p-carousel .p-carousel-content .p-carousel-prev:focus {
        box-shadow: none;
    }
    
    .p-carousel .p-carousel-content .p-carousel-prev:hover,
    .p-carousel .p-carousel-content .p-carousel-next:hover {
        @apply bg-palette-blue-light-50 border border-palette-gray-600 text-palette-gray-600
    }    
    
    .p-carousel .p-carousel-content {    
        .p-carousel-next,
        .p-carousel-prev {
            padding: 0;
            text-align: center;
        }    
        .p-carousel-next .pi-chevron-right,
        .p-carousel-prev .pi-chevron-left {
            width: 20px;
            height: 20px;
            line-height: 20px;
        }
    }
    
    .p-carousel-content button.p-carousel-prev:disabled {
        display: none;
    }
    
    .p-carousel-content button.p-carousel-next:disabled {
        display: none;
    }
    
    .p-carousel .p-carousel-content {    
        .p-carousel-next,
        .p-carousel-prev {
            padding: 0;
            text-align: center;
        }
    
        .p-carousel-next .pi-chevron-right,
        .p-carousel-prev .pi-chevron-left {
            width: 20px;
            height: 20px;
            line-height: 20px;
        }
    }

    .t-cross-sale-card{
        width: 100% !important;
        max-width: 240px !important;
        border-radius: 8px !important;
        margin: 0 6px;
        background-color: transparent !important;
    }

    .t-soctrip-cross-sale .p-carousel .t-fit-content .p-carousel-container .p-carousel-items-content .p-carousel-items-container .p-carousel-item {
        flex: 1 !important;
        min-width: 20%;
        width: none !important;
        max-width: 240px !important;
    }

    @media (min-width: 768px) {
        .t-soctrip-cross-sale .p-carousel .t-fit-content .p-carousel-container .p-carousel-items-content .p-carousel-items-container .p-carousel-item {
            flex: 1 !important;
            min-width: 20%;
            max-width: 240px !important;
        }
    }
    @media (max-width: 767px) {
        .t-soctrip-cross-sale .p-carousel .t-fit-content .p-carousel-container .p-carousel-items-content .p-carousel-items-container .p-carousel-item {
            flex: 1 !important;
            min-width: 20%;
            max-width: 240px !important;
        }
    }

    .t-soctrip-cross-sale .t-menu-tab .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link{
        background-color: #FFFF;
    }
    .t-soctrip-cross-sale .t-menu-tab .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
        background-color: #EFF8FF !important;
    }
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus{
        box-shadow: none !important;
    }
}
// ---------------------------------Cross sale --------------------------
.cross-sale-home-page {
    background: linear-gradient(143deg, rgba(255, 202, 166, 0.12) -22.92%, rgba(250, 100, 137, 0.12) 122.49%), #FFF;
    .t-soctrip-cross-sale {
        padding: 24px !important;       
    }
}
// ---------------------------------Cross sale Hotel detail, Payment success --------------------------

.cross-sale-module {
    .t-soctrip-cross-sale {
        padding: 24px !important;
        margin-top: 24px !important;
        gap: 20px !important;
        background-color: #FFFF !important;
        border-radius: 8px;
        border: 1px solid #EAECF0 !important;
    }
}

.cross-sale-payment-success {
    .t-soctrip-cross-sale {
        gap: 16px !important
    }
    .cross-sale-section {
        padding: 0px !important;
    }
}

.cross-sale-padding-16 {
    .t-soctrip-cross-sale {
        padding: 16px 12px !important;
        .t-header-title {
            margin-left: 4px !important;
        }
    }
}
// -------------------------------------*********--------------------------
