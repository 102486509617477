// Global PrimeNG custom styles
.btn-primary-500-xs {
  @apply border-branding-primary-500 bg-branding-primary-500 text-xs text-palette-base-white;
}
.btn-primary-600-xs {
  @apply border-branding-primary-600 bg-branding-primary-600 text-xs text-palette-base-white;
}
.btn-primary-700-xs {
  @apply border-branding-primary-700 bg-branding-primary-700 text-xs text-palette-base-white;
}

// ************************ Header *********************
.language-setting.p-dialog .p-dialog-content {
  padding: 0 1.5rem 2rem 1.5rem !important;
}

.custom-noti.p-overlay img.w-11 {
  min-width: 2.75rem;
}

.custom-noti.p-overlay .text-sm {
  text-wrap: wrap;
}

.custom-mega-menu .grid{
  margin: 0px !important;
}

.language-setting.p-dialog  .p-dialog-content{
  overflow-y: unset !important;
}

#app-header .top-\[115\%\] {
  top:0px !important
}

.language-setting.p-dialog .p-dropdown .p-dropdown-trigger {
  margin-right: 10px;
}

.language-setting.p-dialog {
  height: fit-content !important;
}

#menu-bar .custom-mega-menu.p-overlay.p-component {
  top: 20px !important;
}

#menu-bar .custom-noti.p-overlay.p-component {
   top: 25px !important;

}

.create-account.p-dialog {
  height: fit-content !important;
}
.text-palette-blue-600 i.sctr-icon-chevron-down{
  color: #1570ef !important;
}

.error-language.p-dialog-mask{  
  height: 100vh !important;
}
.default-lang-dialog.p-dialog .p-dialog-content {
  padding: 1.5rem !important;
}

//************************************************************
.booking-menu {
   ::-webkit-scrollbar {
      width: 7px !important;
  }
}

.range-picker .p-calendar .p-datepicker {
  border: none;
  padding: 0;
}

.range-picker .p-calendar .p-datepicker .p-datepicker-header {
  border-bottom: none !important;
  padding: 0;
  .p-overlaypanel.p-component {
      left: 0px !important;
  }
}

.range-picker .p-datepicker table td {
  padding: 5px 5px !important;
  .p-highlight {
      background: #1570EF;
      color: #FFFFFF;
  }
}

.p-datepicker table td.p-datepicker-today>span {
  border: 1.5px solid #1570EF;
}

.range-picker .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: none;
}

.range-picker .p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next.p-datepicker-next:focus,
.range-picker .p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next.p-datepicker-prev:focus {
  box-shadow: none !important;
}

// -------------------------------****------------------------------------
// -----------------------------Header------------------------
.assistant-btn {
  background: linear-gradient(98.52deg, #fead34 4.54%, #ff7041 87.05%);
  backdrop-filter: blur(2px);
  border-radius: 6px;
}

.box-icon {
  min-height: 20px;
  min-width: 20px;
  max-width: 20px;
  min-width: 20px;
}

.box-avatar {
  background: linear-gradient(white, white) padding-box, linear-gradient(98.52deg, #fead34 4.54%, #ff7041 87.05%) border-box;
  border-radius: 50em;
  border: 2px solid transparent;
}

.add-destination button.p-button {
  border: none;
  height: 52px !important;
}

#container-search .p-button {
  border: none !important;
  background: none !important;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0 !important;
}

.p-overlaypanel:after,
.p-overlaypanel:before {
  display: none;
}

@media screen and (max-width: 1450px) {
  .text-assistant,
  .box-nickname {
      display: none;
  }
}

.search-input {
  --tw-bg-opacity: 0, transition: width 0.3s ease-in-out !important;
  --webkit-transition: width 0.3s ease-in-out;
}

.tooltip-menu .p-tooltip-text {
  font-size: 13px !important;
}

.tooltip-menu.p-tooltip .p-tooltip-text {
  @apply bg-black bg-opacity-40 font-medium text-sm text-gray-200 mt-3
}

.tooltip-menu .p-tooltip-arrow {
  display: none;
}

.shadow-xs {
  box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

#menu-bar .search-box-default {
  height: 40px;
}

#menu-bar .search-box-searching {
  border: 1px solid #84CAFF;
  box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

#menu-bar .search-box {
  border-radius: 22px;
  --tw-bg-opacity: 0, transition: width 0.3s ease-in-out !important;
  --webkit-transition: width 0.3s ease-in-out;
}

#menu-bar .search-box-header {
  width: 100%;
  height: 40px;
  border: 1px solid #eaecf0;
  border-radius: 50px;
  --tw-bg-opacity: 0, transition: width 0.3s ease-in-out !important;
  --webkit-transition: width 0.3s ease-in-out;
}

// -------------------------------------*********---------------------------------
// -------------------------------------*********--------------------------
@layer components {
  //Title at beginning component at homepage
  .title-hotel {
      @apply text-xl font-semibold not-italic text-palette-gray-800 font-inter leading-[30px];
  }
  //Sub title at beginning component at homepage
  .sub-title-hotel {
      @apply text-sm font-normal not-italic text-palette-gray-500 font-inter leading-5 mt-[2px];
  }
  //Text view more at homepage
  .view-more {
      @apply text-base not-italic font-semibold text-palette-blue-700 font-inter;
  }
  //Common text with size 14 and font weight 500
  .text-soctrip-small {
      @apply text-sm not-italic font-medium font-inter leading-5;
  }
}

.popupCustomMapView {
  margin-bottom: 50px;
  .leaflet-popup-content-wrapper {
      padding: 0;
      .leaflet-popup-content {
          margin: 0;
          width: 292px !important;
      }
  }
  .leaflet-popup-tip {
      display: none;
  }
}

.popupCustomMapViewDetail {
  @extend .popupCustomMapView;
  margin-bottom: 70px;
}

.tooltipCustom.p-tooltip {
  .p-tooltip-text {
      background-color: #fff;
      color: #344054;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 600;
  }
}



//*************************Login **************************
.flex-container {
  display: flex;
  flex: 90%;
  align-items: center;
}

.login-container {
  @apply flex flex-col w-full gap-5 mt-8;
  .login-username-input {
      @apply w-full min-h-[56px] border rounded-lg;
  }
  .sign-in-btn {
      @apply w-full bg-branding-primary-700 border border-branding-primary-300 rounded-lg;
      .p-button:hover {
          @apply bg-inherit;
      }
  }
  .sign-in-btn:hover {
      @apply bg-branding-primary-600;
  }
  .sign-in-btn:focus {
      box-shadow: none !important;
  }
  .p-inputtext:focus {
      @apply border-branding-primary-300 outline-none;
      box-shadow: 0 0 0 4px #eaf3ff, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }
  .p-inputtext:hover {
      @apply border-branding-primary-300;
  }
  .p-button.p-button-secondary:enabled:focus {
      box-shadow: unset;
  }
  .login-phone-dropdown {
      @apply ml-2.5;
      .p-dropdown-panel {
          @apply w-[260px] bg-white;
      }
      .p-overlay {
          @apply mt-4 ml-[-12px];
      }
      .p-dropdown {
          @apply flex items-baseline;
      }
      .p-component {
          @apply border-none h-10;
      }
      .p-dropdown-items-wrapper {
          @apply bg-white text-sm min-h-[240px] border-system-gray-200;
      }
  }
  .password-input::placeholder {
      @apply text-xs text-system-gray-500;
  }
  .password-input::-ms-reveal {
      @apply hidden;
  }
  .login-phone-input {
      @apply border-none w-full;
  }
}

//************************************************************
//***************************Sign up*********************************
.background {
  @apply h-auto bg-cover object-contain flex;
  background-image: url('/assets/images/Background.svg');
}

.container {
  @apply hidden;
}

.sign-up-flex-container {
  @apply hidden;
}

.sign-up-container {
  flex: 1;
  display: flex;
  align-items: flex-start;
}

.flex-container {
  display: flex;
  flex: 90%;
  align-items: center;
  justify-content: flex-end;
}

.sign-up-password-input::placeholder {
  @apply text-xs text-system-gray-400;
}

.sign-up-password-input::-ms-reveal {
  @apply hidden;
}

.area-input {
  @apply border-none w-full;
}

.verification-form {
  @apply h-12 flex flex-row justify-start items-start gap-4 p-0;
  .p-inputtext::placeholder {
      @apply text-center text-base;
  }
}

.verification-input {
  @apply h-12 w-14 text-center font-bold;
}

.resend-button {
  @apply bg-white border-none cursor-pointer font-bold text-branding-primary-700;
}

.sign-up-btn {
  @apply w-full bg-branding-primary-700 border border-branding-primary-300 rounded-lg;
  .p-button:hover {
      @apply bg-inherit;
  }
  .p-button:enabled:active {
      @apply border-transparent bg-branding-primary-700;
  }
}

.p-inputtext {
  @apply w-full min-h-[48px] rounded-lg;
}

.p-inputtext:focus {
  border-color: #84caff;
  outline: none;
  box-shadow: 0 0 0 4px #eaf3ff, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.p-inputtext:hover {
  @apply border-branding-primary-300;
}

.sign-up-form {
  @apply flex flex-col gap-4 mt-5;
  .policy-checkbox {
      .p-checkbox .p-checkbox-box {
          @apply rounded-lg;
      }
  }
  .area-dropdown {
      @apply ml-2.5;
      .p-dropdown-panel {
          @apply w-[260px] bg-white;
      }
      .p-overlay {
          @apply mt-4 ml-[-12px];
      }
      .p-dropdown {
          @apply flex items-center;
      }
      .p-component {
          @apply border-none h-10;
      }
      .p-dropdown-items-wrapper {
          @apply bg-white text-sm min-h-[240px] border-system-gray-200;
      }
  }
  .flex-input {
      @apply flex flex-col gap-1;
      input {
          @apply w-full;
      }
  }
  .p-inputtext:focus {
      border-color: #84caff;
      outline: none;
      box-shadow: 0 0 0 4px #eaf3ff, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }
  .p-inputtext:hover {
      @apply border-branding-primary-300;
  }
}

@media (min-width: 768px) {
  .container {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
  }
  .sign-up-container {
      flex: 1.25;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
  }
}
// -------------------------------------*********--------------------------


// ------------------------------------- Footer-------------------------------
.footer-section {
    .p-button {
        border: none;
        width: 100%;
        padding: 10px 18px;
        height: 100%;
        font-weight: 600;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .p-button:hover {
        background: #1570EF;
        border-color: #1570EF;
    }

    .footer-section .p-button:hover {
        background-color: #1570EF;
    }

    .p-button-label {
        font-weight: 600;
        font-size: 1rem;
    }

    @media screen and (max-width: 1720px) {
        .p-button-label {
            font-size: 0.8rem;
        }
    }

    @media screen and (max-width: 1400px) {
        .p-button-label {
            font-size: 1rem;
        }
    }

    .p-component {
        font-family: "Inter";
    }

    .p-inputtext.ng-dirty.ng-invalid {
        border-color: #ced4da;
    }

    .p-inputtext:hover {
        border-color: #ced4da;
    }

    .text-detail {
        color: rgba(52, 64, 84, 1);
        font-size: 16px;
        font-family: "Inter";
        line-height: 24px;
        font-weight: 400;
        font-style: normal;
    }

    .register-btn button:focus {
        box-shadow: none !important;
    }

    .register-btn {
        backdrop-filter: blur(2px);
        border-radius: 6px;
        /* Text md/Semibold */
        border-radius: 8px;
        border: 1px solid var(border-branding-primary-600, #1570EF);
        background: #1570EF;
    }

    .devider {
        background: repeating-linear-gradient(to right, transparent, transparent 10px, #EAECF0 10px, #EAECF0 20px);
    }
}

.location-section {
    .leaflet-container {
        border-radius: 8px !important;
    }
}


// ********************** Calendar *******************
.calendar-dropdown {
  .p-dropdown {
    height: 36px;
    width: 200px;
    align-items: center;
  }

  .p-dropdown .p-dropdown-label.p-placeholder {
    height: 36px;
    padding: 8px 0 8px 16px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .p-inputtext {
    min-height: 0;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #344054;
  }

  .p-dropdown .p-dropdown-trigger {
    margin-right: 16px;
  }
}

.calendar-dropdown-right {
    .p-dropdown {
      height: 44px;
      width: 100%;
      align-items: center;
    }
  
    .p-dropdown .p-dropdown-label.p-placeholder {
      height: 36px;
      padding: 9px 0 8px 16px;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  
    .p-inputtext {
      min-height: 0;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #344054;
    }
  
    .p-dropdown .p-dropdown-trigger {
      margin-right: 16px;
    }

    .p-disabled {
        background-color: #F2F4F7;
    }

    .p-ripple .p-element .p-dropdown-item {
        width: 250px;
    }   

    .p-overlay {
        max-width: 100%;
        left: -1px !important;
    }
}

.calendar-from-input-booking {
    .p-calendar .p-datepicker {
        top: 50px !important;
        left: 4px !important;
    }
    .p-calendar-w-btn .p-inputtext {
        min-height: 0;
        height: 44px;
        border-bottom: 1px solid #D0D5DD;
        border-top: 1px solid #D0D5DD;
        border-right: none;
        border-left: 1px solid #D0D5DD;
    }

    .p-calendar-w-btn .p-datepicker-trigger {
        height: 44px;
        border-bottom: 1px solid #D0D5DD;
        border-top: 1px solid #D0D5DD;
        border-right: 1px solid #D0D5DD;
        border-left: none;
    }

    .p-button:enabled:hover {
        background: none;
        color: #D0D5DD;
    }

    @media (max-width: 1800px) {
        .p-calendar .p-datepicker {
            width: 100%;
        }

        .p-calendar {
            width: 100% !important;
        }
    }

    .p-button:focus {
        box-shadow: none;
    }

    .p-datepicker table td {
        padding: 1px;
    }

    .p-datepicker table td.p-datepicker-today > span {
        background-color: #1570EF;
        color: white;
    }

    .p-datepicker table td > span.p-highlight {
        border: 1px solid #1570EF;
        background-color: rgb(239 248 255);
        color: #1570EF;
    }

    .p-inputtext:focus {
        box-shadow: none;
    }

    .p-inputtext:enabled:focus {
        border: 1px solid #D0D5DD !important;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: none !important;
    }
}

.calendar-to-input-booking {
    .p-calendar .p-datepicker {
        top: 50px !important;
        left: -148px !important;
    }
    .p-calendar-w-btn .p-inputtext {
        min-height: 0;
        height: 44px;
        border-bottom: 1px solid #D0D5DD;
        border-top: 1px solid #D0D5DD;
        border-right: none;
        border-left: 1px solid #D0D5DD;
    }

    .p-calendar-w-btn .p-datepicker-trigger {
        height: 44px;
        border-bottom: 1px solid #D0D5DD;
        border-top: 1px solid #D0D5DD;
        border-right: 1px solid #D0D5DD;
        border-left: none;
    }

    .p-datepicker {
        left: -20% !important;
    }

    .p-button:enabled:hover {
        background: none;
        color: #D0D5DD;
    }

    .p-button:focus {
        box-shadow: none;
    }

    .p-datepicker table td {
        padding: 1px;
    }

    @media (max-width: 1800px) {
        .p-calendar .p-datepicker {
            left: 0px !important;
            width: 100%;
        }

        .p-calendar {
            width: 100% !important;
        }
    }

    // @media (min-width: 1800px) and (max-width: 1920px) {
    //     .p-calendar .p-datepicker {
    //         left: -30% !important;
    //     }
    // }

    .p-datepicker table td.p-datepicker-today > span {
        background-color: #1570EF;
        color: white;
    }

    .p-datepicker table td > span.p-highlight {
        border: 1px solid #1570EF;
        background-color: rgb(239 248 255);
        color: #1570EF;
    }

    .p-inputtext:focus {
        box-shadow: none;
    }

    .p-inputtext:enabled:focus {
        border: 1px solid #D0D5DD !important;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: none !important;
    }
}

.calendar-input-text-booking {
    .p-input-icon-left > .p-inputtext {
        height: 44px;
        min-height: 0;
        width: 100%;
    }

    .p-input-icon-left > i:first-of-type {
        top: 19px;
    }

    .p-inputtext:enabled:hover {
        border-color: #84CAFF;
    }

    .p-input-icon-left > .p-inputtext {
        padding-left: 33px;
    }

    .p-input-icon-left > .p-inputnumber {
        width: 100%;
    }

    .p-inputwrapper {
        display: block;

        .p-inputnumber {
            height: 44px !important;
            width: 100%;
        }
    }
}

// **********************Input phone number *******************
.phone-input-disable {
  .input-phone-booking {
      background-color: #f2f4f7;
  }
}

.phone-input-disabled {
    .input-phone-booking {
        background-color: #EAECF0;
        p-dropdown {
          .p-dropdown.p-component{
              color: #344054;
              background-color: #EAECF0 !important;
              opacity: 1 !important;
          }
        }
        input {
            background-color: #EAECF0;
        }
    }
}

.input-phone-booking {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  height: 44px;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  .input-phone-booking .p-inputtext {
      width: 100%;
      height: 42px;
      min-height: 42px;
      padding: 12px 4px 12px 16px;
      border: none !important;
  }
  .p-dropdown:not(.p-disabled).p-focus {
      border: none !important;
      box-shadow: none;
  }
  .p-dropdown {
      border: none !important;
  }

  .p-dropdown-panel {
      border: 1px solid #84caff;
  }

  .p-dropdown-filter-container {
      border: 1px solid #84caff;
      border-radius: 4px;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      margin: 4px;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
      color: #495057;
      margin: 4px;
      border-radius: 6px;
      border: 1px solid #e9ecef;
      background: #e9ecef;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      margin: 6px;
      color: #1570EF;
      border-radius: 6px;
      background: #EAF3FF;
  }
  .p-dropdown.p-component.p-disabled {
      background-color: #f2f4f7 !important;
      color: #344054;
      opacity: 1 !important;
  }
}

.input-phone-booking .p-inputtext {
  width: 100%;
  height: 42px;
  min-height: 42px;
  padding: 12px 4px 12px 12px;
  border: none;
  outline: none;
  border-radius: 0px 8px 8px 0px;
  &:focus {
      border: none !important;
      outline: none;
      box-shadow: none;
  }
}

// Custom styles for tag p-dropdown
.input-phone-booking .p-dropdown {
  padding: 0px !important;
  display: flex;
  align-items: center;
  width: 98px;
  height: 40px;
  border: 1px solid transparent;
}

.input-phone-booking .p-dropdown-panel {
  min-width: 240px;
}

// ------------------------- Toast --------------------------
$bg_error: #FFFBFA;
$bd_error: #FDA29B;
$color_error: #B42318;
$bg_warning: #FFFCF5;
$bd_warning: #FEC84B;
$color_waring: #B54708;
$bg_success: #F6FEF9;
$bd_success: #6CE9A6;
$color_success: #027A48;
$bg_info: #FFF;
$bd_info: #D0D5DD;
$color_info: #344054;
$bg_primary: #EFF8FF;
$bd_primary: #1570EF;
$color_primary: #101828;
$close_icon_error: '/assets//images//common-icon//error-close.svg';
$close_icon_warning: '/assets//images//common-icon//warning-close.svg';
$close_icon_success: '/assets//images//common-icon//success-close.svg';
$close_icon_info: '/assets//images//common-icon//info-close.svg';
$close_icon_primary: '/assets//images//common-icon//info-primary.svg';
.toast-booking {
    .p-toast {
        font-family: Inter !important;
        font-weight: 700 !important;
        opacity: 1 !important;
        top: 5.75rem;
        width: 29rem;
        right: 1.5rem;
        max-height: 17rem !important;
        overflow: hidden;
    }
    .p-toast-message-error {
        border: 1px solid $bd_error !important;
        background-color: $bg_error !important;
        opacity: 1 !important;
        color: $color_error !important;
        border-radius: 12px !important;
        .p-toast-icon-close-icon {
            width: 1.25rem !important;
            height: 1.25rem !important;
            background-image: url($close_icon_error) !important;
            background-repeat: no-repeat !important;
        }
    }
    .p-toast-message-warning {
        color: $color_waring !important;
        background-color: $bg_warning !important;
        border: 1px solid $bd_warning !important;
        border-radius: 12px !important;
        .p-toast-icon-close-icon {
            width: 1.25rem !important;
            height: 1.25rem !important;
            background-image: url($close_icon_warning) !important;
            background-repeat: no-repeat !important;
        }
    }
    .p-toast-message-success {
        color: $color_success !important;
        background-color: $bg_success !important;
        border: 1px solid $bd_success !important;
        border-radius: 12px !important;
        .p-toast-icon-close-icon {
            width: 1.25rem !important;
            height: 1.25rem !important;
            background-image: url($close_icon_success) !important;
            background-repeat: no-repeat !important;
        }
    }
    .p-toast-message-info {
        color: $color_info !important;
        background-color: $bg_info !important;
        border: 1px solid $bd_info !important;
        border-radius: 12px !important;
        .p-toast-icon-close-icon {
            width: 1.25rem !important;
            height: 1.25rem !important;
            background-image: url($close_icon_info) !important;
            background-repeat: no-repeat !important;
        }
    }

    .p-toast-message-primary {
        color: $color_primary !important;
        font-weight: 500;
        background-color: $bg_primary !important;
        border: 1px solid $bd_primary !important;
        border-radius: 12px !important;
        .p-toast-icon-close-icon {
            width: 1.25rem !important;
            height: 1.25rem !important;
            background-image: url($close_icon_primary) !important;
            background-repeat: no-repeat !important;
        }
    }

    .p-link:focus {
        box-shadow: none !important;
    }
    .p-toast-icon-close {
        height: 100% !important;
    }
    .p-toast-message-content {
        width: 100% !important;
        border: none !important;
        padding: 0 !important;
    }
    .p-toast-message {
        display: flex !important;
        box-shadow: none !important;
        border-radius: none !important;
        transition: none !important;
        height: fit-content !important;
        word-break: break-all !important;
        white-space: pre-wrap !important;
        width: 100%;
        padding: 1rem !important;
    }
    .p-toast-icon-close:hover {
        background: none !important;
    }
    .p-toast-icon-close-icon::before {
        content: none !important;
    }
    .toast-content {
        height: fit-content;
        line-height: 1.25rem;
        width: 100%;
        margin: 0 0.75rem;
        font-size: 0.875rem;
        word-break: break-all;
        white-space: pre-wrap;
    }
}

.ota-location-form {
    .p-inputtext {
        min-height: unset;
        height: 44px;
    }
    .p-dropdown-panel {
        .p-dropdown-items {
            .p-dropdown-item {
                color: #495057;

                &.p-highlight {
                    color: black;
                }
            }
        }
    }
    .p-dropdown .p-dropdown-label {
        color: #344054;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
    }
    p-dropdown.p-element.p-inputwrapper {
        height: 44px;
    }
    .p-dropdown.p-component {
        min-width: 100%;
        min-height: unset;
        height: 44px;
        max-width: 80%;
        &.p-disabled {
            background-color: #EAECF0 !important;
            opacity: 1 !important;
    
            .p-dropdown-label.p-placeholder {
                opacity: .6 !important;
            }
        }
    }
    .p-dropdown .p-dropdown-label.p-placeholder {
        margin: 0 !important;
        height: 44px;
        min-height: unset;
        width: 1rem;
        color: #98A2B3;
    }
    .p-dropdown .p-dropdown-label .p-inputtext {
        align-items: center;
        width: 160px;
        min-height: 44px;
        height: 44px;
        font-size: 14px !important;
        font-family: 'Inter';
        outline: none;
        color: #344054 !important;
      }
    .p-dropdown-trigger {
        width: 3rem !important;
        margin: 0 !important;
        padding: 12px 8px 12px 0;
        .pi.pi-chevron-down {
            font-size: 20px;
        }
    }
    .distance-field {
        .is-disable {
            .p-inputnumber-input {
                color: #344054;
                background-color: #EAECF0 !important;
                opacity: 1 !important;
            }
        }
        span.p-inputnumber.p-component {
            width: 100%;
            height: 44px;
            &:hover {
                border-color:#84caff !important;
            }
        }
        .p-inputnumber-input {
            min-height: unset;
            border-radius: 0.5rem 0 0 0.5rem;
            &:hover {
                border-color:#84caff !important;
            }
        }
        .p-dropdown.p-component {
            border-radius: 0 0.5rem 0.5rem 0;
            height: 44px !important;
        }
    }
}

// CUSTOM POPUP
p-confirmdialog.custom-popup {
  .p-dialog {
      width: 544px;

      .p-dialog-header {
          font-family: Inter;
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
          color: #101828;

          .p-dialog-header-icons {
              .p-dialog-header-close {
                  span {
                      font-size: 20px;
                      color: #667085;
                  }
              }
          }
      }

      .p-dialog-content {
          background-color: white !important;
          padding: 0 1.5rem 1.5rem !important;

          .p-confirm-dialog-message {
              font-family: Inter;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              margin: unset;
              color: #475467;
          }
      }

      .p-dialog-footer {
          button {
              height: 44px;
              font-family: Inter;
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              outline: none;

              &:hover {
                  background-color: unset;
                  opacity: .8;
              }

              .p-button-icon {
                  display: none;
              }

              span {
                  font-weight: 600;
              }
          }

          button.btn-cancel-reject {
              color: #344054;
              border: 1px solid #D0D5DD;
          }

          button.btn-cancel-accept {
              color: #FFFFFF;
              background-color: #D92D20;
              border: none;
          }

          button.btn-save-as-draft-reject {
              color: #344054;
              border: 1px solid #D0D5DD;
          }

          button.btn-save-as-draft-accept {
              color: #FFFFFF;
              background-color: #1570EF;
              border: none;
          }
          button.btn-back-accept {
              color: #344054;
              border: 1px solid #D0D5DD;
          }

          button.btn-back-reject {
              color: #FFFFFF;
              background-color: #1570EF;
              border: none;
          }
      }
  }
}

p-confirmdialog.custom-back-popup .p-dialog-footer{
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.confirm-sign-out-btn .p-button.p-button-text {
    background-color: #175cd3 !important; 
    color: #ffffff !important; 
    border-color: transparent; 
  }

.cancel-sign-out-btn .p-button.p-button-text {
    background-color: #ffffff !important; 
    color: #344054 !important; 
    border-color: #175cd3 !important; 
  }

.py-6 {
     padding-top: 24px !important; 
     padding-bottom: 24px !important; 
}
.px-6 {
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.confirm-language-btn  .p-button:focus,
.cancel-language-btn .p-button:focus {
  box-shadow: none !important;
  outline: none !important;

}


.cancel-language-btn .p-button:enabled:hover,
.confirm-language-btn .p-button:enabled:hover {
    background: #175cd3 !important;
    color: #ffffff !important;
    border-color: transparent !important;

}

.cancel-language-btn .p-button:enabled:hover {
    background: #ffffff !important;
    color: #344054 !important;
    border-color: #d0d5dd !important;
}

.language-setting {
    .p-dropdown-filter-container {
        border: 1px solid #84caff;
        border-radius: 4px;
    }
  
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
        margin: 4px;
    }
  
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
        color: #495057;
        margin: 4px;
        border-radius: 6px;
        border: 1px solid #e9ecef;
        background: #e9ecef;
    }
  
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
        margin: 6px;
        color: #1570EF !important;
        border-radius: 6px;
        background: #d9d9d9;
    }
}

.default-lang-dialog.p-dialog .p-dialog-header,
.default-lang-dialog.p-dialog .p-dialog-footer,
.default-lang-dialog.p-dialog .p-dialog-content{
  background-color: #ffffff !important; 
}

.default-lang-dialog.p-dialog .p-dialog-content {
   border-bottom-left-radius: 0px !important; 
   border-bottom-right-radius: 0px !important; 
}

//       ota management sidebar
.ota-management-sidebar {
    .p-sidebar .p-sidebar-content {
        padding: 0;
    }

    .p-sidebar-footer {
        display: none;
    }
}

.location-map-ota {
    .leaflet-container {
        z-index: 0;
    }
}

.view-billing {
    .p-dialog .p-dialog-header {
       padding-top: 12px !important;
       padding-bottom: 12px !important;
    }
}

// location-popup
.location-popup {
    .p-dialog .p-dialog-header .p-dialog-header-icon:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
    }

    .p-dialog {
        border-radius: 12px;
    }

    .p-dialog-header {
        padding-bottom: 0;
    }
}